import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import "video-react/dist/video-react.css";

class Safety_Tips_view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    }
    this.flag = 0;
  }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      fetch(myConstClass.BASE_URL + '/safety_tips/getSafetyTipsView/' + answer_array['3'])
        .then(response => response.json())
        .then(response => this.setState({ posts: response }))
        .catch(err => console.log(err))
    }

    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }

  }

  render() {
    const { posts } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Safety Tips View
            </h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Date : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{posts.message_date}</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Title : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{posts.title}</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Message : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{posts.message}</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Date/Time : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{posts.created_at}</h5>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Safety_Tips_view);
