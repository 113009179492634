import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const MAX_LENGTH = 500;

const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      alert('in..' + e.target.value);
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Bulletin_news_edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      edit_id: '',
      formData: {
        selectedImage: '',
      },
     // selectedImage: '',
      submitted: false,
      isButtonDisabled: true,
      isButtonDisabled1: true,
      editorState: EditorState.createEmpty(),
      textremaining: 500,
      errors: {},
      type: 1,
    }
    
  }
  onEditorStateChange = (editorState) => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    this.setState({ textremaining: 500 - currentContentLength })
    this.setState({
      editorState,
    });
    this.editor_description = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
  };
  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      this.state.edit_id = answer_array['3'];
      fetch(myConstClass.BASE_URL + '/bulletin_news/getUpdateBulletinNewsInfo/' + answer_array['3'])
        .then(response => response.json())
        .then(response => {
          this.setState({ formData: response })
          if(response.editor_description !="") {

      
          this.setState({
            editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(response.editor_description)))
          })
          let desc = EditorState.createWithContent(convertFromRaw(JSON.parse(response.editor_description)));
          console.log(JSON.stringify(convertToRaw(desc.getCurrentContent())));
          this.editor_description = response.editor_description;
    
          const currentContent = desc.getCurrentContent();
          const currentContentLength = currentContent.getPlainText('').length;
          this.setState({ textremaining: 500 - currentContentLength })
          this.setState({ type: response.type })
        }
        }).catch(err => console.log(err))
    }
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  onImageFileChange = event => {
    const errors = {};
    const size = (event.target.files[0].size / 1024).toFixed(2);
    if (size > 500) {
      alert("The file must be less than 500 kb");
      toast('The file must be less than 500 kb', { containerId: 'B', type: toast.TYPE.ERROR })
      errors["image_error"] = "The file must be less than 500 kb";
    } else {
      errors["image_error"] = "";
    }
    this.setState({ selectedImage: event.target.files[0] });
    this.setState({ errors: errors });
  };

  handleSubmit = () => {
    let form = document.querySelector('#pform');
    const errors = {};
    const formData = new FormData(form);
   // alert(this.state.selectedImage );
    if (localStorage.getItem('uid')) {
      formData.append('user_id', localStorage.getItem('uid'));
    }
    if (this.state.selectedImage !== undefined) {
      if (typeof this.state.selectedImage != 'undefined' || this.state.selectedImage != "") {
        const size = (this.state.selectedImage.size / 1024).toFixed(2);
        if (size > 500) {
          toast('The file must be less than 500 kb', { containerId: 'B', type: toast.TYPE.ERROR })
          errors["image_error"] = "The file must be less than 500 kb";
          this.setState({ errors: errors });
        } else {
          errors["image_error"] = "";
          this.setState({ errors: errors });
          formData.append(
            "selectedImage",
            this.state.selectedImage,
            this.state.selectedImage.name
          );
        }
      }
    }

    //selectedVideo
    if (this.state.selectedVideo !== undefined) {
      if (typeof this.state.selectedVideo != 'undefined' || this.state.selectedVideo != "") {
        const size = (this.state.selectedVideo.size / 1024 / 1024).toFixed(2);

        const fsize = (this.state.selectedVideo.size).toFixed(2);
        const current_file_size = Math.round((fsize / 1024));

        // if (size > 2) {
        if (current_file_size > 20480) {
          toast('The file must be less than 50 mb', { containerId: 'B', type: toast.TYPE.ERROR })
          errors["video_error"] = "The file must be less than 50 mb";
          this.setState({ errors: errors });
        } else {
          errors["video_error"] = "";
          this.setState({ errors: errors });
          formData.append(
            "selectedVideo",
            this.state.selectedVideo,
            this.state.selectedVideo.name
          );
        }
      }
    }
    this.setState({ submitted: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL + `/bulletin_news/UpdateBulletinNewsInfo/` + this.state.edit_id, {
        method: 'POST',
        body: formData,
        // body: JSON.stringify($('#pform').serializeArray()),
      }).then(response => response.json()).then((response) => {
        if (response == true) {
          toast('Bulletin News Updated Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
          this.props.history.push('/forms/bulletin_news/');
        } else {
          toast('Bulletin News Update Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
        }
      }).catch();
    });
  }

  _handleBeforeInput = () => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length
    if (currentContentLength > MAX_LENGTH - 1) {
      console.log('you can type max ten characters');
      return 'handled';
    }
  }

  _handlePastedText = (pastedText) => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length
    if (currentContentLength + pastedText.length > MAX_LENGTH) {
      console.log('you can type max ten characters');
      return 'handled';
    }
  }

  handleRadioChange = (event) => {
    this.setState({ type: event.target.value });
    const { formData } = this.state;
    formData['type'] = event.target.value;
    this.setState({ formData });
  };

  onVideoFileChange = event => {
    const errors = {};
    //   const size = (event.target.files[0].size / 1024).toFixed(2);
    const size = event.target.files[0].size / 1024 / 1024; // in MiB

    const fsize = event.target.files[0].size ;
    const current_file_size = Math.round((fsize / 1024));
    // if (size > 2) {
    if (current_file_size >  51200) {
      alert("The file must be less than 50 mb");
      toast('The file must be less than 50 mb', { containerId: 'B', type: toast.TYPE.ERROR })
      errors["video_error"] = "The file must be less than 50 mb";
    } else {
      errors["video_error"] = "";
    }
    this.setState({ selectedVideo: event.target.files[0] });
    this.setState({ errors: errors });
  };
  onAudioFileChange = event => {
    const errors = {};
    //   const size = (event.target.files[0].size / 1024).toFixed(2);
    const size = event.target.files[0].size / 1024 / 1024; // in MiB
    const fsize = event.target.files[0].size ;
    const current_file_size = Math.round((fsize / 1024));
    if (current_file_size >  5120) {
      alert("The file must be less than 5 mb");
      toast('The file must be less than 5 mb', { containerId: 'B', type: toast.TYPE.ERROR })
      errors["audio_error"] = "The file must be less than 5 mb";
    } else {
      errors["audio_error"] = "";
    }
    this.setState({ selectedVideo: event.target.files[0] });
    this.setState({ errors: errors });
  };

  render() {
    const { formData, submitted, isButtonDisabled, isButtonDisabled1, editorState, type } = this.state;
     return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Edit Bulletin News</h3>
          </Col>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <div className="card__title">
              </div>
              <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
              </div>
               <ValidatorForm
                 className="material-form" id="pform"
                 onSubmit={this.handleSubmit}
               >
                 <Row>
                   <Col md={1} lg={1}>  <h6>Type :</h6></Col>
                   <Col md={5} lg={5}>
                     <Input
                       className=" radio-btn__radio-custom"
                       style={{ marginTop: "0px", marginLeft: "10px" }}
                       type="radio"
                       id="1"
                       value="1"
                       onChange={this.handleRadioChange}
                       checked={formData.type == "1"}
                     /> Text  &nbsp; &nbsp;
                     <Input
                       className="  radio-btn__radio-custom"
                       style={{ marginTop: "0px", marginLeft: "10px" }}
                       type="radio"
                       id="2"
                       value="2"
                       onChange={this.handleRadioChange}
                       checked={formData.type == "2"}
                     /> Image  &nbsp; &nbsp;
                     <Input
                       className="  radio-btn__radio-custom"
                       style={{ marginTop: "0px", marginLeft: "10px" }}
                       type="radio"
                       id="3"
                       value="3"
                       onChange={this.handleRadioChange}
                       checked={formData.type == "3"}
                     /> Video  &nbsp; &nbsp;
                     <Input
                       className="  radio-btn__radio-custom"
                       style={{ marginTop: "0px", marginLeft: "10px" }}
                       type="radio"
                       id="4"
                       value="4"
                       onChange={this.handleRadioChange}
                       checked={formData.type == "4"}
                     /> Audio  &nbsp; &nbsp;
                     <div>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6} lg={6}>
                    <h6>Bulletin News Title</h6>
                    <TextValidator
                      onChange={this.handleChange}
                      name="title"
                      // label="Title"
                      value={formData.title}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      className="material-form__field"
                    />
                    <div>
                    </div>
                  </Col>
                </Row>
                {/* {type == 1 ? ( */}
                  <Row>
                    <Col md={6} lg={6}>
                      <h6  >Bulletin News Description</h6>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChange}
                        handleBeforeInput={this._handleBeforeInput}
                        handlePastedText={this._handlePastedText}
                      />
                      <Input type="hidden" name="description" value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} />
                      <Input type="hidden" name="editor_description" value={this.editor_description} />

                      {/* {(this.editor_description) ?
                      <Input type="hidden" name="description" value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} /> : ''}
                    <Input type="hidden" name="editor_description" value={this.editor_description} /> */}
                      <hr />
                      <div>
                      </div>
                    </Col>
                  </Row>
                {/* ) : (""
                )} */}
                <Row>
                  <Col md={6} lg={6} style={{ color: 'red', textAlign: 'right' }}>
                    ({this.state.textremaining} / 500)
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="material-form__field"
                        onChange={this.handleDateChange}
                        name="news_date"
                        placeholder="News Date"
                        label="News Date (yyyy-mm-dd)"
                        value={formData.news_date}
                        format="yyyy-MM-dd"
                      />
                    </MuiPickersUtilsProvider>
                    <div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    <h6> News Source</h6>
                    <TextValidator
                      onChange={this.handleChange}
                      name="news_source"
                      // label="News Source"
                      value={formData.news_source}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      className="material-form__field"
                    />
                    <div>
                    </div>
                  </Col>
                </Row>
                {type == 2 ? (
                  <Row>
                    <Col md={6} lg={6}>
                      <h6  >Image</h6>
                      <img src={formData.image_path} alt="image" style={{ height: '200px' }} />
                      <div>
                      </div>
                    </Col>
                  </Row>
                ) : (""
                )}
                {type == 2 ? (
                  <Row>
                    <Col md={6} lg={6}>
                      <h6>Select Image</h6>
                      <input type="file"
                        onChange={this.onImageFileChange}
                        name="image"
                      // value={formData.image}
                      />
                      <br />
                      <span className="">
                        (Note: The file must be less than 500 kb)
                      </span>
                      <br />
                      <span className="red-text">
                        {this.state.errors["image_error"]}
                      </span>
                      <div>
                      </div>
                    </Col>
                  </Row>
                ) : (""
                )}

                {type == 3 ? (
                  <Row>
                    <Col md={6} lg={6}>
                      <h6  >Video</h6>
                      <a href={formData.video_path} target="_blank">{formData.video}</a>
                      <div>
                      </div>
                    </Col>
                  </Row>
                ) : (""
                )}
                {type == 3 ? (
                  <Row>
                    <Col md={6} lg={6}>
                      <h6>Select Video</h6>
                      <input type="file"
                        onChange={this.onVideoFileChange}
                        name="video"
                      />
                      <br />
                      <span className="">
                        (Note: The file must be less than 50 mb)
                      </span>
                      <br />
                      <span className="red-text">
                        {this.state.errors["video_error"]}
                      </span>
                      <div>
                      </div>
                    </Col>
                  </Row>
                ) : (""
                )}

                {type == 4 ? (
                  <Row>
                    <Col md={6} lg={6}>
                      <h6  >Audio</h6>
                      <a href={formData.audio_path} target="_blank">{formData.audio}</a>
                      <div>
                      </div>
                    </Col>
                  </Row>
                ) : (""
                )}
                {type == 4 ? (
                  <Row>
                    <Col md={6} lg={6}>
                      <h6>Select Audio</h6>
                      <input type="file"
                        onChange={this.onAudioFileChange}
                        name="audio"
                      />
                      <br />
                      <span className="">
                        (Note: The file must be less than 5 mb)
                      </span>
                      <br />
                      <span className="red-text">
                        {this.state.errors["audio_error"]}
                      </span>
                      <div>
                      </div>
                    </Col>
                  </Row>
                ) : (""
                )}

                <Row>
                  <Col md={6} lg={6}>
                    <br />
                    <h6>Created Date/Time</h6>
                    {formData.created_at}
                    <div>
                    </div>
                  </Col>
                </Row>
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                >
                  {
                    (submitted && 'Your form is submitted!')
                    || (!submitted && 'Submit')
                  }
                </Button>
              </ValidatorForm>
            </CardBody>
          </Card>
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(Bulletin_news_edit));
