/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { compose, withProps, withStateHandlers } from 'recompose';
import {
  GoogleMap, Marker, withGoogleMap, withScriptjs,
} from 'react-google-maps';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import CloseIcon from 'mdi-react/CloseIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const MapWithAMarker = compose(
  withProps({
    // generate your API key
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAmW78ILd6zQ5wR5t7xYzQaJctup2Bqft0&v=3.'
    + 'exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="map" style={{ height: '360px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withStateHandlers(() => ({
    isOpen: true,
  }), {
    onToggleOpen: ({ isOpen }) => () => ({
      isOpen: !isOpen,
    }),
  }),
  withScriptjs,
  withGoogleMap,
)(props => (
  <GoogleMap
    defaultZoom={13}
    defaultCenter={{ lat: 18.5584495, lng: 73.7852128 }}
  > 
    {props.isMarkerShown
      && (        
        <div>      
      <Marker position={{ lat: 18.5584495, lng: 73.7852128 }} onClick={props.onToggleOpen}>        
      </Marker>
      <Marker position={{ lat: 18.6191745, lng: 73.8600045 }} onClick={props.onToggleOpen}>      
      </Marker>
    </div>
      )}
  </GoogleMap>
));

const BasicMap = ({ t,posts}) => (
 
  <Col xs={12} md={12} lg={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">{t('maps.google_map.pin_with_info_window')}</h5>
        </div>
        <MapWithAMarker posts isMarkerShown/>
      </CardBody>
    </Card>
  </Col>
);

BasicMap.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(BasicMap);
