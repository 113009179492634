import React, { PureComponent } from 'react';
import { browserHistory, Router, Route,withRouter,Redirect } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import * as myConstClass from '../../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      toDashboard: false,
    };

    this.showPassword = this.showPassword.bind(this);
    this.login = this.login.bind(this);
  }

  login(e) {

    e.preventDefault();
    //console.log('this.refs',this.props.history.push('/dashboard_default'));
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    console.log('-->', formData);
    fetch(myConstClass.BASE_URL+`/login/verifyLogin/`, {
    method: 'POST',
    body: JSON.stringify($('#pform').serializeArray()),
    }).then(response =>  response.json()).then((response) => {

      if(response != false){

        // 7-may-2022
        var sarray = response.split('-');
        if(sarray['1'] == "false" && sarray['3'] == "Invalid User") {
          toast('Invalid User', {containerId: 'B',type: toast.TYPE.ERROR})
        } else if(sarray['1'] == "false" && sarray['3'] == "Inactive User") {
            toast('Inactive User', {containerId: 'B',type: toast.TYPE.ERROR})
        } else if(sarray['1'] == "false" && sarray['3'] == "Suspended User") {
            toast('Suspended User', {containerId: 'B',type: toast.TYPE.ERROR})
        } else {

        
        var sarray = response.split('-');

        localStorage.setItem('uname', sarray['2']);
        localStorage.setItem('utype', sarray['1']);
        localStorage.setItem('session_id', sarray['0']);
        localStorage.setItem('uid', sarray['3']);
        //alert(localStorage.getItem('uname'));
        this.setState(() => ({
          toDashboard: true
        }))
        }   
      }else{
        toast('Login Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
      }
    })
    .catch();

    
  }

  showPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  render() {
    const { handleSubmit } = this.props;
    const { showPassword } = this.state;
    if (this.state.toDashboard === true) {
      // return <Redirect to='/dashboard_default' />
      return <Redirect to='/forms/evidence_list' />
    }
    return (
        <form className="form" id="pform">
        <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
        <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="username"
              component="input"
              type="text"
              placeholder="Name"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            ><EyeIcon />
            </button>
          </div>
          {/* <div className="account__forgot-password">
            <a href="/easydev/log_in">Forgot a password?</a>
          </div> */}
        </div>
        {/* <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label="Remember me"
            />
          </div>
        </div> */}
        <div className="account__btns">
        <button className="btn btn-xs btn-primary custom_btn" type="button" onClick={this.login}>Sign In</button>
          
          {/* <Link className="btn btn-outline-primary account__btn" to="/register">Create
            Account
          </Link> */}
          
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'log_in_form', // a unique identifier for this form
})(LogInForm);
