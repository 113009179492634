import React,{ Component } from 'react';
import { Card, CardBody, Col, Container, Row,Button,Form,FormGroup,Input,Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { MDBDataTable } from 'mdbreact';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'; 
import Datatable from 'react-bs-datatable';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import { Editor} from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,convertFromRaw  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class Terms extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      posts: [],
      editorState: EditorState.createEmpty(),
      submitted: false
    };
    
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    //this.content =1;
    this.content = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
    //console.log('log:',{draftToHtml(convertToRaw(editorState.getCurrentContent()))});
  };

  handleSubmit = () => {
 
    this.setState({ submitted: true }, () => {
      
        setTimeout(() => this.setState({ submitted: false }), 5000);
        //alert('in..');
        fetch(myConstClass.BASE_URL+`/user/terms/`, {
                method: 'POST',
                body: JSON.stringify($('#pform').serializeArray()),
              }).then(response =>  response.json()).then((response) => {
                if(response == true){
                  toast('Info Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                  //this.props.history.push('/forms/about_us/');
                }else{
                  toast('Info Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
                }
              })
              .catch();
    });
  }

  componentDidMount() {
    
    fetch(myConstClass.BASE_URL+`/user/getInfo/3`)
    .then(response => response.json()) 
   // .then(response => {this.setState({editorState:JSON.parse(response)}) })  
    .then(response =>{this.setState({editorState: EditorState.createWithContent(
      convertFromRaw(JSON.parse(response))
    )})
  })
    .catch(err => console.log(err))

    if(!localStorage.getItem('session_id')){
      console.log('History:',this.props);      
      this.props.history.push('/log_in');
  }
  }

render() {
  const { editorState} = this.state
  return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Terms
       </h3>
      </Col>
      <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    </Row>
    <Row>
    <Col md={12} lg={12}>
    <Card>
      <CardBody>
      <Form id="pform" >
           
      <Editor
                    editorState={editorState}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={this.onEditorStateChange}
/>
{ (this.content) ?
            <Input type="hidden" name="api_content" value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} /> :''}
            <Input type="hidden" name="content" value={this.content} />
           <Button type="button" size="sm" color="primary" onClick={this.handleSubmit}>Save</Button>
             
              </Form>
      </CardBody>
      </Card>
      </Col>
    </Row>
  </Container>)
}
}

export default withTranslation('common')(Terms);
