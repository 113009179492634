import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import AnimatedLineFormWithLabels from './components/AnimatedLineFormWithLabels';
import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AutoComplete from 'material-ui/AutoComplete';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Link } from 'react-router-dom';
import DatePicker1 from 'react-datepicker';

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illnois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];
const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class FloatingLabelClass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      user_id: '',
      posts: [],
      branch: [],
      codes: [],
      formData: {
        sapcode: '',
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        mobile_no: '',
        designation: '',
        department: '',
        branch_name: '',
        vertical: '',
        blood_group: '',
        gender: '',
        mpin: '',
        date_of_birth: Date.now(),
        age: '',
        reporting_manager_sap_code: '',
        reporting_manager_name: '',
        reviewing_manager_sap_code: '',
        reviewing_manager_name: '',
        ho_poc_sap_code: '',
        ho_poc_name: '',
        department_head_sap_code: '',
        department_head_name: '',
        status: '',
        city: '',
        admin_select: ''
      },
      session_tenant_id: '',
      AdminData: [],
      admin_select: 1,
      submitted: false,
      start_date: new Date(),
    }
  }
  componentDidMount() {

    fetch(myConstClass.BASE_URL + '/user/getBranch/')
      .then(response => response.json())
      .then(response => { this.setState({ branch: response }) }).catch(err => console.log(err))

    fetch(myConstClass.BASE_URL + '/user/getAllSAPCode/')
      .then(response => response.json())
      .then(response => { this.setState({ codes: response }) }).catch(err => console.log(err))

    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
    if (localStorage.getItem('uid')) {
      if (localStorage.getItem('uid') != 1) {
        this.setState({ session_tenant_id: localStorage.getItem('uid') })
      }
    }
    fetch(myConstClass.BASE_URL + '/user/getAdmin')
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          i++;
        });
        this.setState({ AdminData: response })
      })
      .catch(err => console.log(err))
  }
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleUpdateInput = (searchText) => {
    const { formData } = this.state;
    formData['reporting_manager_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleNewRequest = (searchText) => {
    const { formData } = this.state;
    formData['reporting_manager_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleNewRequest1 = (searchText) => {
    const { formData } = this.state;
    formData['reviewing_manager_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleUpdateInput1 = (searchText) => {
    const { formData } = this.state;
    formData['reviewing_manager_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleNewRequest2 = (searchText) => {
    const { formData } = this.state;
    formData['ho_poc_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleUpdateInput2 = (searchText) => {
    const { formData } = this.state;
    formData['ho_poc_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleNewRequest3 = (searchText) => {
    const { formData } = this.state;
    formData['department_head_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleUpdateInput3 = (searchText) => {
    const { formData } = this.state;
    formData['department_head_sap_code'] = searchText;
    this.setState({ formData });
  };

  handleDateChange = (date) => {
    const { formData } = this.state;
    formData['date_of_birth'] = date;
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);//alert(age);
    formData['age'] = age;
    this.setState({ formData });
  };
  handleSubmit = () => {
    var start_date = $("#start_date").val();
    var licence_days = $("#licence_days").val();
    if (start_date != "" && licence_days == "") {
      alert("Please select days")
    } else if (start_date == "" && licence_days == "") {
      alert("Please select Start date or Licence Days")
    } else {
      this.setState({ submitted: true }, () => {
        setTimeout(() => this.setState({ submitted: false }), 5000);
        fetch(myConstClass.BASE_URL + `/user/check_add_user_mobile_number`, {
          method: 'POST',
          body: JSON.stringify($('#pform').serializeArray()),
        }).then(response => response.json()).then((response) => {
          if (response == 0) {
            fetch(myConstClass.BASE_URL + `/user/add_user`, {
              method: 'POST',
              body: JSON.stringify($('#pform').serializeArray()),
            }).then(response => response.json()).then((response) => {
              if (response == true) {
                toast('User Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
                this.props.history.push('/forms/users/');
              } else {
                toast('User Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
              }
            }).catch();
          } else {
            toast('Mobile number already exists.', { containerId: 'B', type: toast.TYPE.ERROR })
          }
        }).catch();
      });
    }
  }
  handleAdminChange = (event) => {
    this.setState({ admin_select: event.target.value });
    const { formData } = this.state;
    formData['admin_select'] = event.target.value;
    this.setState({ formData });
  }
  handleStartDateChange = (date) => {
    this.setState({ start_date: date });
    const { formData } = this.state;
    formData['start_date'] = date;
    this.setState({ formData });
  }
  handleLicenceDaysChange = (event) => {
    const { formData } = this.state;
    formData['licence_days'] = event.target.value;
    this.setState({ formData });
    this.setState({ licence_days: event.target.value });
  }
  render() {
    const { formData, submitted, branch, codes } = this.state;
    const { posts, start_date } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            {this.state.user_id ? <span><h3 className="page-title" style={{ "display": "inline" }}>Edit User</h3>
            </span> : <h3 className="page-title">Add User </h3>}
          </Col>
        </Row>
        <Row>

          <Card>
            <CardBody>
              <div className="card__title"></div>
              <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
              </div>

              <ValidatorForm
                className="material-form" id="pform"
                onSubmit={this.handleSubmit}
              >
                <Row>
                  <Col md={6} lg={6}>
                    <div>
                      {this.state.session_tenant_id == "" ?
                        <div>
                          <FormControl className="material-form__field">
                            <InputLabel htmlFor="admin_select">Select Tenant</InputLabel>
                            <Select onChange={this.handleAdminChange} inputProps={{
                              name: 'admin_select',
                              id: 'admin_select',
                            }} value={this.state.admin_select} >
                              {/* <MenuItem value={0}>Select Tenant</MenuItem> */}
                              {this.state.AdminData.map((number) =>
                                <MenuItem value={number.uid}>{number.first_name}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        :
                        <input type="hidden" id="admin_select" name="admin_select" value={this.state.session_tenant_id} />}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={7} lg={7}>
                    Start date
                    &nbsp;
                    <DatePicker1
                      className='form-control'
                      style={{ width: "50%" }}
                      dateFormat="dd-MM-yyyy"
                      selected={start_date}
                      name="start_date"
                      onChange={this.handleStartDateChange}
                      id="start_date"
                    />
                    &nbsp;
                    Days
                    &nbsp;
                    <input
                      className='form-control'
                      style={{ width: "20%", display: "inline" }}
                      type="text"
                      placeholder=""
                      required
                      onChange={this.handleLicenceDaysChange}
                      name="licence_days"
                      id="licence_days"
                      validators={['matchRegexp:^[0-9]{1,6}$']}
                      errorMessages={['Enter maximum 6 digit Number..']}
                    />
                    <br />
                    <br />
                  </Col>

                </Row>
                <Row>
                  <Col md={6} lg={6}>

                    <TextValidator
                      onChange={this.handleChange}
                      name="firstname"
                      placeholder="First name"
                      label="First name"
                      className="material-form__field"
                      value={formData.firstname}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="middlename"
                      placeholder="Middle name"
                      label="Middle name"
                      value={formData.middlename}
                    />
                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="lastname"
                      placeholder="Last name"
                      label="Last name"
                      value={formData.lastname}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="email"
                      placeholder="Email"
                      label="Email"
                      value={formData.email}
                      validators={['required', 'isEmail']}
                      errorMessages={['this field is required', 'Enter valid email address']}
                    />

                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="mobile_no"
                      placeholder="Mobile No"
                      label="Mobile No"
                      value={formData.mobile_no}
                      validators={['required', 'matchRegexp:^[0-9]{10}$']}
                      errorMessages={['this field is required', 'Enter 10 digit Number..']}
                    />
                    <br />
                  </Col>

                  <Col md={6} lg={6}>
                    <div>
                      <FormControl className="material-form__field">
                        <InputLabel htmlFor="blood_group">Blood Group</InputLabel>
                        <Select
                          value={formData.blood_group}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'blood_group',
                            id: 'blood_group',
                          }}
                        >
                          <MenuItem value={1}>A+</MenuItem>
                          <MenuItem value={2}>B+</MenuItem>
                          <MenuItem value={3}>O+</MenuItem>
                          <MenuItem value={4}>AB+</MenuItem>
                          <MenuItem value={5}>A-</MenuItem>
                          <MenuItem value={6}>B-</MenuItem>
                          <MenuItem value={7}>O-</MenuItem>
                          <MenuItem value={8}>AB-</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl className="material-form__field">
                        <InputLabel htmlFor="gender">Gender</InputLabel>
                        <Select
                          value={formData.gender}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'gender',
                            id: 'gender',
                          }}
                        >
                          <MenuItem value={1}>Male</MenuItem>
                          <MenuItem value={2}>Female</MenuItem>
                          <MenuItem value={3}>Others</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="material-form__field"
                        onChange={this.handleDateChange}
                        name="date_of_birth"
                        placeholder="Date of Birth"
                        label="Date of Birth"
                        value={formData.date_of_birth}
                        format="dd-MM-yyyy"
                      />
                    </MuiPickersUtilsProvider>

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="age"
                      placeholder="Age"
                      label="Age"
                      value={formData.age}
                      validators={['required', 'matchRegexp:^[0-9]{1,3}$']}
                      errorMessages={['this field is required', 'Enter maximum 3 digit Number..']}
                    />
                    <br />

                    <div>
                      <FormControl className="material-form__field">
                        <InputLabel htmlFor="age-simple">Status</InputLabel>
                        <Select
                          value={formData.status}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'status',
                            id: 'status',
                          }}
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={2}>Inactive</MenuItem>
                          <MenuItem value={3}>Suspended</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="city"
                      placeholder="Address"
                      label="Address"
                      value={formData.city}
                    />
                    <br />
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={6} lg={6}>
                    <label>Emergency Number 1</label>
                    <MuiThemeProvider >
                      <AutoComplete
                        searchText={formData.reporting_manager_sap_code}
                        onUpdateInput={this.handleUpdateInput}
                        onNewRequest={this.handleNewRequest}
                        fullWidth={true}
                        hintText="Emergency Contact Number"
                        name="reporting_manager_sap_code"
                        dataSource={codes}
                      />
                    </MuiThemeProvider>
                  </Col>
                  <Col md={6} lg={6}>
                    <label>Emergency Number 2</label>
                    <MuiThemeProvider >
                      <AutoComplete
                        searchText={formData.reviewing_manager_sap_code}
                        onUpdateInput={this.handleUpdateInput1}
                        onNewRequest={this.handleNewRequest1}
                        fullWidth={true}
                        hintText="Emergency Contact Number"
                        name="reviewing_manager_sap_code"
                        dataSource={codes}
                      />
                    </MuiThemeProvider>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col md={6} lg={6}>
                    <label>Emergency Number 3</label>
                    <MuiThemeProvider >
                      <AutoComplete
                        searchText={formData.ho_poc_sap_code}
                        onUpdateInput={this.handleUpdateInput2}
                        onNewRequest={this.handleNewRequest2}
                        fullWidth={true}
                        hintText="Emergency Contact Number"
                        name="ho_poc_sap_code"
                        dataSource={codes}
                      />
                    </MuiThemeProvider>
                  </Col>
                  <Col md={6} lg={6}>
                    <label>Emergency Number 4</label>
                    <MuiThemeProvider >
                      <AutoComplete
                        searchText={formData.department_head_sap_code}
                        onUpdateInput={this.handleUpdateInput3}
                        onNewRequest={this.handleNewRequest3}
                        fullWidth={true}
                        hintText="Emergency Contact Number"
                        name="department_head_sap_code"
                        dataSource={codes}
                      />
                    </MuiThemeProvider>
                  </Col>
                </Row>
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                >
                  {
                    (submitted && 'Your form is submitted!')
                    || (!submitted && 'Submit')
                  }
                </Button>
              </ValidatorForm>
            </CardBody>
          </Card>
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(FloatingLabelClass));
