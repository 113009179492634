import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarMail from './TopbarMail';
import TopbarNotification from './TopbarNotification';
import TopbarSearch from './TopbarSearch';
import TopbarLanguage from './TopbarLanguage';
const logo = `${process.env.PUBLIC_URL}/img/captain_india.png`;
const admin_logo = `${process.env.PUBLIC_URL}/img/captain_india.png`;
class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    return (
      <div>
      {(localStorage.getItem('utype') =='2') || localStorage.getItem('utype') == '1' ? (
  
      <div className={localStorage.getItem('utype') == '1' ? "topbar" : "topbar_admin" }  style={{ backgroundColor: 'white' }}  >
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            {localStorage.getItem('utype') == '1' ?

            <Link to="/dashboard_default" ><img className="top_logo" src={logo} alt="avatar" /></Link> :

            <Link to="/dashboard_default" ><img className="top_logo" src={admin_logo} alt="avatar" /></Link>}
          </div>
          <div className="topbar__right">            
            <TopbarProfile />
          </div>
        </div>
      </div>
        ): null}
        </div>
    );
  }
}

export default Topbar;
