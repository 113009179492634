import React,{ Component } from 'react';
import { Col, Container, Row ,UncontrolledTooltip} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TooltipTop from '../../UI/Tooltips/components/TooltipTop';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import PropTypes from 'prop-types';
//import BasicMap from './components/BasicMap';
import PinWithInfoWindow from './components/PinWithInfoWindow';
//import MonochromeMap from './components/MonochromeMap';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import GoogleMapReact from 'google-map-react';
const loader = `${process.env.PUBLIC_URL}/Rolling-1s-37px.gif`;
const image = `${process.env.PUBLIC_URL}/img/marker.png`;
const image2 = `${process.env.PUBLIC_URL}/img/red_pin.png`;
const AnyReactComponent = ({ lat,lng,text}) => <div><a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + lat +","+lng}><img src={image2} style={{cursor:'pointer'}} title={text}/></a></div>;
const AnyReactComponent2 = ({ lat,lng,text}) => <div><a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + lat +","+lng}><img src={image} style={{cursor:'pointer'}} title={text}/></a></div>;
class Map extends Component {
  static defaultProps = {
    center: {
      lat: 18.5584495,
      lng: 73.7852128
    },
    zoom: 11
  };
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    }
  }

  handleGoogleMapApi = (google) => {

    const answer_array = this.props.location.pathname.split('/');
   // alert(answer_array['3']);
    if(answer_array['3']){
      fetch(myConstClass.BASE_URL+'/user/getLocationTracking/'+answer_array['3'])
      .then(response => response.json())
      .then(response =>{
        //alert(response.origin);
        var directionsService = new google.maps.DirectionsService;
        var directionsRenderer = new google.maps.DirectionsRenderer;
        // var map = new google.maps.Map(document.getElementById('map'), {
        //   zoom: 6,
        //   center: {lat: 41.85, lng: -87.65}
        // });
        directionsRenderer.setMap(google.map);

       // document.getElementById('submit').addEventListener('click', function() {
          this.calculateAndDisplayRoute(directionsService, directionsRenderer,response.origin,response.end,response);
      })
      .catch(err => console.log(err))
    }     
    
        //});
    // var flightPath = new google.maps.Polyline({
    //   path: this.state.posts,
    //   geodesic: true,
    //   strokeColor: '#FF4F00',
    //   strokeOpacity: 1,
    //   strokeWeight: 5
    // });

    // flightPath.setMap(google.map);
  }
  calculateAndDisplayRoute(directionsService, directionsRenderer,origin,end,response) {
    var waypts = [];
    //var checkboxArray = ["18.558975216468,73.772811890347"];
    var checkboxArray = response.result;
    var k = Math.floor(checkboxArray.length/21);//alert(Math.floor(k));
    var cnt=1;
    for (var i = 0; i < checkboxArray.length; i++) {
      
     // if (checkboxArray.options[i].selected) {
       if(i % k == 0 && cnt < 22){//alert('in..');
        waypts.push({
          location: checkboxArray[i].lat+','+checkboxArray[i].lng,
          stopover: true
        });
        cnt++;
      }
     // }
    }
    console.log('waypts :',waypts);

    directionsService.route({
      origin: origin,
      destination: end,
      waypoints: waypts,
      optimizeWaypoints: true,
      travelMode: 'DRIVING'
    }, function(response, status) {
      if (status === 'OK') {
        directionsRenderer.setDirections(response);
        var route = response.routes[0];
        // var summaryPanel = document.getElementById('directions-panel');
        // summaryPanel.innerHTML = '';
        // // For each route, display summary information.
        // for (var i = 0; i < route.legs.length; i++) {
        //   var routeSegment = i + 1;
        //   summaryPanel.innerHTML += '<b>Route Segment: ' + routeSegment +
        //       '</b><br>';
        //   summaryPanel.innerHTML += route.legs[i].start_address + ' to ';
        //   summaryPanel.innerHTML += route.legs[i].end_address + '<br>';
        //   summaryPanel.innerHTML += route.legs[i].distance.text + '<br><br>';
        // }
        $('#loader').hide();
      } else {
        window.alert('Directions request failed due to ' + status);
      }
    });
  }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
   // alert(answer_array['3']);
    if(answer_array['3']){
      fetch(myConstClass.BASE_URL+'/user/getLocationTracking/'+answer_array['3'])
      .then(response => response.json())
      .then(response =>{
        
        this.setState({posts: response})
        $('#loader').hide();
      })
      .catch(err => console.log(err))
    }   
    if(!localStorage.getItem('session_id')){
      console.log('History:',this.props);      
      this.props.history.push('/log_in');
  }     
  }

  render() {
    const { posts} = this.state; //alert(posts.length);
    console.log('posts',posts);
    return (
      <Container>
      <Row>
      <Col md={12}>
        <h3 className="page-title">Tracking Details&nbsp;&nbsp;<img src={loader}  id="loader" /></h3>      
      </Col>
    </Row>
    <Row> 
    
    {posts  ?
    <div style={{ height: '75vh', width: '99%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDpif0MifD3A5SuVDkL8ZZh3-lWvybR-Pg'}}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          //yesIWantToUseGoogleMapApiInternals
          //onGoogleApiLoaded={this.handleGoogleMapApi}
        
        >
        {posts.map((place,i) => (
        (i=='0'||i==(posts.length-1)) ?
          <AnyReactComponent
            lat={place.lat}
            lng={place.lng}
            text={place.timestamp}
            // text={place.addr}
          /> : <AnyReactComponent2
          lat={place.lat}
          lng={place.lng}
          text={place.timestamp}
          // text={place.addr}
        />

        ))}
        </GoogleMapReact>
      </div> :''}
    {/* <PinWithInfoWindow posts={posts}/> */}
    </Row>
  </Container>)
  }
}
export default withTranslation('common')(Map);
