import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import "video-react/dist/video-react.css";

class Evidence_view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    }
  }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      fetch(myConstClass.BASE_URL + '/user/getUserPoshDetail/' + answer_array['3'])
        .then(response => response.json())
        .then(response => this.setState({ posts: response }))
        .catch(err => console.log(err))
    }
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }

  render() {
    const { posts } = this.state;
    var heading = '';
    if (posts != null) {
      if (posts.last_name)
        posts.last_name = posts.last_name;
      else
        posts.last_name = '';
      heading = posts.first_name + ' ' + posts.last_name + '(' + posts.mobile_no + ')';
    }

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Posh - {heading}
            </h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                {posts ?
                  <Row>
                    <Col md={12} lg={12}>
                      <h3>Posh Detail</h3>
                      <div style={{ 'padding-top': '25px', 'font-size': '16px', 'color': '#646777' }}><b>Date:</b> {posts.date_value}  </div>
                      <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Name of the employee raising the posh:</b> {posts.name_raising_posh}</div>
                      <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Employee Id:</b> {posts.emp_id}</div>
                      <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Department:</b> {posts.department}</div>
                      <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Details of the incident:</b> {posts.incident_detail}</div>
                      <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Date when Occurred:</b> {posts.occurred_date}</div>
                      <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Time of occurrece:</b> {posts.occurrece_time}</div>
                      <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Location:</b> {posts.location}</div>
                      <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Name/s of the employee involved:</b> {posts.involved_emp_name}</div>
                      <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Employee Id/s:</b> {posts.involved_emp_id}</div>
                      <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Department/s:</b> {posts.involved_emp_department}</div>
                      {/* <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Description of the incident (when occurred):</b> {posts.incident_description}</div> */}
                    </Col>
                  </Row>
                  :
                  <Row>
                    <Col md={12} lg={12}>
                      <h5>No Record Found</h5>
                    </Col></Row>
                }
                {/* <hr /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Evidence_view);
