import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import { Map, GoogleApiWrapper, Marker, InfoWindow, Polyline } from 'google-maps-react';
import '../../../style_css.css';
import $ from 'jquery';

class TrackingTripDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      tid: "",
      trackee_id_name: "",
      curr_lat: "",
      curr_lng: "",
      curr_id: "",
      curr_trackee_time: "",
      curr_firebase_arr: [],
      selected_div: "",
      loading: true,
      showInfoWindow: true,
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      triangle_coords: ""
    }
  }

  componentDidMount() {

    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
      this.setState({ tid: tid })
    }
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      $('#loader').show();
      var user_id = answer_array['3'];
      fetch(myConstClass.BASE_URL + '/user/getUserTripDetail/' + user_id)
        .then(response => response.json())
        .then(response => {
          var data_array = response.data_array;
          let i = 1;
          data_array.forEach(element => {
            element.sr_no = i;
            i++;
          });
          this.setState({ formData: data_array })
          this.setState({ trackee_id_name: response.trackee_id_name })
          this.setState({ selected_div: 1 })

          if (response.first_record_tracking_id != "") {
            fetch(myConstClass.BASE_URL + '/user/getUserFirebaseArray/' + response.first_record_tracking_id)
              .then(response => response.json())
              .then(response => {
                this.setState({ curr_lat: response.map_center_lat })
                this.setState({ curr_lng: response.map_center_lng })
                this.setState({ curr_trackee_time: response.map_center_time })
                this.setState({ selected_div: 1 })
                this.setState({ curr_firebase_arr: response.map_array })
                this.setState({ showingInfoWindow: false })
                this.setState({ activeMarker: null })
                this.setState({ triangle_coords: response.triangle_coords })
                $('#loader').hide();
              })
              .catch(err => console.log(err))
          }
          $('#loader').hide();
        })
        .catch(err => console.log(err))
    }
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }

  viewMapDetail(id, e, e2, e3, sr_no) {
    $('#loader').show();
    if (id) {
      fetch(myConstClass.BASE_URL + '/user/getUserFirebaseArray/' + id)
        .then(response => response.json())
        .then(response => {
          this.setState({ curr_lat: e })
          this.setState({ curr_lng: e2 })
          this.setState({ curr_trackee_time: e3 })
          this.setState({ selected_div: sr_no })
          this.setState({ curr_firebase_arr: response.map_array })
          this.setState({ showingInfoWindow: false })
          this.setState({ activeMarker: null })
          this.setState({ triangle_coords: response.triangle_coords })
          $('#loader').hide();
        })
        .catch(err => console.log(err))
    } else {
      $('#loader').hide();
    }
  }

  viewInfo(id, lng) {
    this.setState({
      showInfoWindow: true
    });
  }
  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    });

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });

  render() {
    const { trackee_id_name, curr_lat, curr_lng, curr_trackee_time, selected_div, loading, showInfoWindow, triangle_coords } = this.state;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">  Trips Detail - {trackee_id_name}
            </h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>

        <Row>
          <Col md={3} lg={3} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
            <Card>
              <CardBody style={{ padding: '0px' }}>
                <div style={{ height: 'calc(100vh - 120px) ', overflow: 'scroll', scrollbarWidth: 'thin' }}>
                  {this.state.formData.map(ele => (
                    <div style={{ border: '1px solid gray', padding: '5px', marginBottom: '15px', cursor: 'pointer' }}
                      onClick={() => this.viewMapDetail(ele.id, ele.trackee_lat, ele.trackee_lng, ele.trackee_time, ele.sr_no)}
                      class={`${selected_div == ele.sr_no ? "border-red" : ""}`}
                    >
                      <div style={{ backgroundColor: '#d2d2d2', padding: '5px', fontWeight: 'bold' }} class={`${selected_div == ele.sr_no ? "bg-blue" : ""}`}>
                        &nbsp; Trip - {ele.id}
                      </div>
                      <div>
                        {/* Location : {ele.trackee_lat}, {ele.trackee_lng}
                        <br /> */}
                        Start Time: {ele.start_time}
                        <br />
                        End Time: {ele.end_time}
                        <br />
                        Traker: {ele.tracker_id}
                        <br />
                        {/* <span onClick={() => this.viewMapDetail(ele.trackee_lat, ele.trackee_lng, ele.trackee_time)} style={{ fontWeight: 'bold', cursor: 'pointer', color: '#1890ff' }}>Click to View Map</span>
                        <br /> */}
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={9} lg={9} style={{ paddingRight: '0px', paddingLeft: '1px' }}>
            <Card style={{ height: 'calc(100vh - 120px) ', scrollbarWidth: 'thin' }}>
              <div  >
                <div id="loader" className={` ${loading ? '' : ' loaded'}`} style={{ display: 'none', height: 'calc(100vh - 120px) ', textAlign: 'center', marginTop: '100px' }}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="map-area"  >

                {this.state.curr_firebase_arr ?
                  <Map
                    google={this.props.google}
                    zoom={14}
                    center={{
                      lat: curr_lat, lng: curr_lng
                    }}>
                    {this.state.curr_firebase_arr.map(ele => (
                      <Marker
                        key={ele.id}
                        position={{ lat: ele.lat, lng: ele.lng }}
                        title={ele.date_value + " - " + ele.lat + "," + ele.lng}
                        icon={ele.icon}
                        // onClick={() => this.viewInfo(ele.lat, ele.lng)}
                        name={ele.date_value}
                        onClick={this.onMarkerClick}
                        place_={'Current location'}
                      />
                    ))}
                    <Polyline
                      path={triangle_coords}
                      strokeColor="#0000FF"
                      strokeOpacity={0.8}
                      strokeWeight={2} />
                    <InfoWindow
                      marker={this.state.activeMarker}
                      onClose={this.onInfoWindowClose}
                      visible={this.state.showingInfoWindow}
                    >
                      <div>
                        <h4>{this.state.selectedPlace.name}</h4>
                      </div>
                    </InfoWindow>
                  </Map>
                  : ''}
                {/* {this.state.curr_lat != "" ?
                  <Map
                    google={this.props.google}
                    zoom={10}
                    center={{
                      lat: curr_lat, lng: curr_lng
                    }}>
                    <Marker
                      key={curr_lat}
                      position={{ lat: curr_lat, lng: curr_lng }}
                      title={curr_lat + "," + curr_lng + " - " + curr_trackee_time}
                      icon={"/img/map2.png"}
                    // icon={ele.icon_value}
                    />
                  </Map>
                  : ''} */}
              </div>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

// export default withTranslation('common')(TrackingTripDetail);

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAHx_0IxRG0ZMtykjswkH_F9uiWMIrcVj8'
})(TrackingTripDetail);