import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { CSVLink, CSVDownload } from "react-csv";

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "title",
    text: "Title",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "duration",
    text: "Duration",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "price",
    text: "Price",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "created_at",
    text: "Date/Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "action",
    text: "Action",
    sort: true,
    headerStyle: { width: "10%" }
  },
];
const image = `${process.env.PUBLIC_URL}/img/map-location.png`;
class Posh_Evidence_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData: [],
      tid: "",
    }
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'idd', filterable: true },
      { title: 'Employee Name', prop: 'first_name', filterable: true },
      { title: 'Employee SAP Code', prop: 'sap_code' },
      { title: 'Employee Mobile No.', prop: 'mobile_no' },
      { title: 'Employee Email', prop: 'email' },
      { title: 'Latitude', prop: 'latitude' },
      { title: 'Longitude', prop: 'longitude' },
      { title: 'Date', prop: 'date' },
      { title: 'Time', prop: 'time' },
    ];
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    fetch(myConstClass.BASE_URL + '/user/getPlans')
      .then(response => response.json())
      .then(response => {
        let key = 1;
        response.forEach(element => {
          var id = element.id;
          element.sr_no = key;
          element.status = element.status == '1' ? "Active" : "Inactive";
          element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.removePlan(id)}></i><i id={id} className="fa fa-edit ptr_css" onClick={() => this.editPlan(id)}></i></div>;
          key++;
        });
        this.setState({ formData: response })
      })
      .catch(err => console.log(err))
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }

  editPlan(e) {
    this.props.history.push('/forms/edit_plan/' + e);
  }

  removePlan(e) {
    this.flag = 1;
    fetch(myConstClass.BASE_URL + `/user/deletePlan/` + e, {
      method: 'POST',
      body: e,
      //  }).then(toast('Plan Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS }),
    }).then(response => response.json()).then((response) => {
      if (response == true) {
        toast('Plan Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
        fetch(myConstClass.BASE_URL + '/user/getPlans')
          .then(response => response.json())
          .then(response => {
            let key = 1;
            response.forEach(element => {
              var id = element.id;
              element.sr_no = key;
              element.status = element.status == '1' ? "Active" : "Inactive";
              element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.removePlan(id)}></i><i id={id} className="fa fa-edit ptr_css" onClick={() => this.editPlan(id)}></i></div>;
              key++;
            });
            this.setState({ formData: response })
          })
          .catch(err => console.log(err))
      } else {
        toast('Failed to delete.', { containerId: 'B', type: toast.TYPE.ERROR })
      }
    }).catch();
  }
  render() {
    const { tid } = this.state;
    const csv_headers = [
      { label: "#", key: "id" },
      { label: "Title", key: "title" },
      { label: "Duration", key: "duration" },
      { label: "Status", key: "status" },
      { label: "Date/Time", key: "created_at" },
    ];
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Plans
            </h3>
            <div style={{ float: 'right' }}>
              {this.state.formData ?
                <CSVLink filename={"Plan.csv"}
                  className="btn btn-primary" style={{}} data={this.state.formData} headers={csv_headers}>Export</CSVLink>
                : ''}
            </div>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          // sizePerPage: 25,
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Posh_Evidence_list);
