import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "sr_no",
    text: "#",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "user_full_name",
    text: "First Name Last Name",
    sort: true,
    headerStyle: { width: "20%" }
  },
  {
    dataField: "mobile_no",
    text: "Mobile",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "device_no",
    text: "Device No",
    sort: true,
    headerStyle: { width: "10%" }
  },
  // {
  //   dataField: "latitude",
  //   text: "Latitude",
  //   sort: true,
  //   headerStyle: { width: "10%" }
  // },
  // {
  //   dataField: "longitude",
  //   text: "Longitude",
  //   sort: true,
  //   headerStyle: { width: "10%" }
  // },
  {
    dataField: "created_at",
    text: "Date/Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
];

const image = `${process.env.PUBLIC_URL}/img/map-location.png`;
class Device_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData: [],
    }
    this.flag = 0;
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {

    fetch(myConstClass.BASE_URL + '/user/getDevice')
      .then(response => response.json())
      .then(response => {
        console.log(response);
        //this.setState({ formData: response })
      })
      .catch(err => console.log(err))
    // fetch(myConstClass.BASE_URL + '/user/getUserPoshEvidence')
    fetch(myConstClass.BASE_URL + '/user/getUserDevice')
      .then(response => response.json())
      .then(response => {
        this.setState({ formData: response })
      })
      .catch(err => console.log(err))
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }

  render() {
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">All Tracking Devices
            </h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          // sizePerPage: 25,
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Device_list);
