import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import * as myConstClass from '../../../constant.js';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import '../../../style_css.css';

const mapStyles = {
  width: '100%',
  height: '100%'
};
export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      tid: "",
      formData_close_request: 0,
      formData_open_request: 0,
      formData_center_lat: "",
      formData_center_lng: ""
    }
    this.flag = 0;
  }
  checkLoation() {

    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
      this.setState({ tid: tid })
    }
    fetch(myConstClass.BASE_URL + '/user/getTodayTracking/' + tid)
      .then(response => response.json())
      .then(response => {
        var data_array = response.data_array;
        let i = 1;
        data_array.forEach(element => {
          var id = element.id;
          element.id = i;
          i++;
        });
        this.setState({ formData: data_array })
        this.setState({ formData_close_request: response.close_request })
        this.setState({ formData_open_request: response.open_request })
        this.setState({ formData_center_lat: response.center_arr.lat })
        this.setState({ formData_center_lng: response.center_arr.lng })
      })
      .catch(err => console.log(err))
  }

  componentDidMount() {
    this.interval = setInterval(() => this.checkLoation(), 50000);

    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
      this.setState({ tid: tid })
    }
    fetch(myConstClass.BASE_URL + '/user/getTodayTracking/' + tid)
      .then(response => response.json())
      .then(response => {
        var data_array = response.data_array;
        let i = 1;
        data_array.forEach(element => {
          var id = element.id;
          element.id = i;
          i++;
        });
        this.setState({ formData: data_array })
        this.setState({ formData_close_request: response.close_request })
        this.setState({ formData_open_request: response.open_request })
        this.setState({ formData_center_lat: response.center_arr.lat })
        this.setState({ formData_center_lng: response.center_arr.lng })
      })
      .catch(err => console.log(err))
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }

  render() {
    const { formData, formData_center_lat, formData_center_lng } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <div style={{ float: 'left', fontWeight: 'bold', display: 'inline' }} >
              <h3 className="page-title">Live Tracking</h3>
            </div>
            <div style={{ float: 'right', fontWeight: 'bold', display: 'inline' }}>
              <span style={{ color: 'green', fontWeight: 'bold' }}>Active Request - {this.state.formData_open_request}</span>
              &nbsp;  &nbsp;
              <span style={{ color: 'red', fontWeight: 'bold' }}>Inactive Request - {this.state.formData_close_request}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ paddingRight: '0px', paddingLeft: '1px' }} className="map-div">
            <div style={{ height: '80vh ', width: '70vw' }}>
              {/* <Map
        google={this.props.google}
        zoom={11}
        style={mapStyles}
        initialCenter={{ lat: 18.51957,   lng: 73.85535  }}
      /> */}

              {/*   <div className="map-area">
                <Map
            google={this.props.google}
            zoom={11}
            center={{ lat: 18.51957, lng: 73.85535 }}>
            <Marker key="marker_1"
              position={{ lat: 17.273268, lng: 74.542020 }}
            />
          </Map>  
              </div> */}

              <div className="map-area" style={{ maxWidth: '99%' }}>
                {this.state.formData != "" ?
                  <Map
                    google={this.props.google}
                    zoom={10}
                    center={{
                      // lat: 18.51957, lng: 73.85535 
                      lat: formData_center_lat, lng: formData_center_lng
                    }}>
                    {this.state.formData.map(ele => (
                      <Marker
                        key={ele.id}
                        position={{ lat: ele.lat, lng: ele.lng }}
                        title={ele.name + "- " + ele.date_value + " - " + ele.lat + "," + ele.lng}
                        icon={ele.icon_value}
                      />
                    ))}
                  </Map>
                  :
                  <Map
                    google={this.props.google}
                    zoom={2}
                    center={{
                      lat: 16.3834192, lng: 59.0634966
                    }}>
                    {/* <Marker
                      key="mark_1"
                      position={{ lat: 16.3834192, lng: 59.0634966  }}
                      title={ "1"}
                      // onClick={() => this.view(id)}
                      // icon={ "http://localhost:3000/img/map2.png" } 
                      // icon={ele.icon_value}
                    /> */}

                  </Map>
                }
              </div>
              {/* <Map
                    google={this.props.google}
                    zoom={14}
                    center={{  lat: 47.444,   lng: -122.176   }}
                >
                   {this.state.formData.map((cell, i) => {
              return(<Marker key= {"marker_11"+i}
              icon={{
                url: 'http://localhost:3000/img/map2.png',
                // anchor: new google.maps.Point(17, 46),
                // scaledSize: new google.maps.Size(37, 37)
            }}
            position={{  lat:  {cell.lat}, lng: {cell.lng}  }}
            ></Marker>);
            })}
          </Map> */}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAHx_0IxRG0ZMtykjswkH_F9uiWMIrcVj8'
})(MapContainer);