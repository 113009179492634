import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import $ from 'jquery';
import { CSVLink, CSVDownload } from "react-csv";
import { ButtonToolbar, Modal } from 'reactstrap';
import DatePicker1 from 'react-datepicker';

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "uid",
    text: "#",
    sort: true,
    headerStyle: { width: "5%" }
  },
  {
    dataField: "licence_key",
    text: "Licence key",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "start_date",
    text: "Start date",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "end_date",
    text: "End date",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "user_name",
    text: "User",
    sort: true,
    headerStyle: { width: "10%" }
  },
];

class Admin_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData: [],
      submitted: false,
      tenant_id: '',
      //  start_date: Date.now(),
      formData_total_count: 0,
      formData_available_count: 0,

      modal: false,
      start_date: new Date(),
    }
    this.toggle = this.toggle.bind(this);
    this.handleLicenceSubmit = this.handleLicenceSubmit.bind(this);

    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'id', filterable: true },
      { title: 'First Name', prop: 'first_name', filterable: true },
      { title: 'Middle Name', prop: 'middle_name', filterable: true },
      { title: 'Last Name', prop: 'last_name', filterable: true },
      { title: 'User Email', prop: 'email' },
      { title: 'User Mobile No.', prop: 'mobile_no' },
      { title: 'Gender', prop: 'gender' },
      { title: 'User Age', prop: 'age' },
      { title: 'Blood Group', prop: 'blood_group' },
      { title: 'Date of Birth', prop: 'date_of_birth' },
      { title: 'User Status', prop: 'status' },
      { title: 'Action', prop: 'action', cell: (row) => <div><i id={row.id} className="fa fa-remove ptr_css" onClick={() => this.remove(row.id)}></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-edit ptr_css"></i>&nbsp;&nbsp;<i id={row.id} className="fa fa-eye ptr_css"></i></div> },
    ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };

  }

  remove(e) {
    this.flag = 1;
    fetch(myConstClass.BASE_URL + `/user/deleteAdmin`, {
      method: 'POST',
      body: e,
    }).then(toast('User Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS }),

      fetch(myConstClass.BASE_URL + '/user/getAdmin')
        .then(response => response.json())
        .then(response => {
          let i = 1;
          response.forEach(element => {
            let id = element.uid;
            element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} className="fa fa-edit ptr_css"></i></div>;
            element.uid = i;
            i++;
          });
          this.setState({ formData: response })
        })
        .catch(err => console.log(err))
    )
      .catch();
  }

  edit(e) {
    this.props.history.push('/forms/add_admin/' + e);
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      this.setState({ tenant_id: answer_array['3'] })
      fetch(myConstClass.BASE_URL + '/user/getAdminLicence/' + answer_array['3'])
        .then(response => response.json())
        .then(response => {
          let i = 1;
          let ct_available = 0;
          response.forEach(element => {
            let id = element.uid;
            element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i><i id={id} className="fa fa-eye ptr_css" onClick={() => this.viewLicence(id)}></i></div>;
            element.uid = i;
            if (element.user_name == "") {
              ct_available++;
            }
            i++;
          });
          this.setState({ formData: response })
          this.setState({ formData_total_count: response.length })
          this.setState({ formData_available_count: ct_available })
        })
        .catch(err => console.log(err))
    }
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }

    const { formData } = this.state;
    formData['start_date'] = this.state.start_date;
    this.setState({ formData });
    //this.setState({ start_date: this.state.start_date });

  }
  viewLicence(e) {
    this.props.history.push('/forms/licence_list/' + e);
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmit = () => {
    var tenant_id = this.state.tenant_id;

    this.setState({ submitted: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL + `/user/addAdminLicence/` + tenant_id, {
        method: 'POST',
        body: JSON.stringify($('#pform').serializeArray()),
      }).then(response => response.json()).then((response) => {
        if (response == true) {
          toast('Licence Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
          //   this.props.history.push('/forms/admin_list/');
          fetch(myConstClass.BASE_URL + '/user/getAdminLicence/' + tenant_id)
            .then(response => response.json())
            .then(response => {
              let i = 1;
              response.forEach(element => {
                let id = element.uid;
                element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i><i id={id} className="fa fa-eye ptr_css" onClick={() => this.viewLicence(id)}></i></div>;
                element.uid = i;
                i++;
              });
              this.setState({ formData: response })
            })
            .catch(err => console.log(err))
        } else {
          toast('Licence Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
        }
      })
        .catch();
    });
  }

  handleStartDateChange = (date) => {
    const { formData } = this.state;
    formData['start_date'] = date;
    this.setState({ formData });
    this.setState({ start_date: date });
  };

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  handleLicenceSubmit(event) {
    var tenant_id = this.state.tenant_id;
    var start_date = $("#start_date").val();
    var licence_days = $("#licence_days").val();
    var licence = $("#licence").val();
    if (licence == '') {
      toast('Please select licence', { containerId: 'B', type: toast.TYPE.ERROR })

    } else if (start_date != '' && licence_days == '') {
      toast('Please select Licence Days', { containerId: 'B', type: toast.TYPE.ERROR })
    } else if (start_date == '' && licence_days == '') {
      toast('Please select Start date or Licence Days', { containerId: 'B', type: toast.TYPE.ERROR })
    } else {
      this.setState({ submitted: true }, () => {
        setTimeout(() => this.setState({ submitted: false }), 5000);
        fetch(myConstClass.BASE_URL + `/user/addAdminLicence/` + tenant_id, {
          method: 'POST',
          body: JSON.stringify($('#lform').serializeArray()),
        }).then(response => response.json()).then((response) => {
          if (response == true) {
            toast('Licence Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
            this.setState(prevState => ({ modal: !prevState.modal }));
            fetch(myConstClass.BASE_URL + '/user/getAdminLicence/' + tenant_id)
              .then(response => response.json())
              .then(response => {
                let i = 1; let ct_available = 0;
                response.forEach(element => {
                  let id = element.uid;
                  element.action = <div><i id={id} className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i><i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i><i id={id} className="fa fa-eye ptr_css" onClick={() => this.viewLicence(id)}></i></div>;
                  element.uid = i;
                  if (element.user_name == "") {
                    ct_available++;
                  }
                  i++;
                });
                this.setState({ formData: response })
                this.setState({ formData_total_count: response.length })
                this.setState({ formData_available_count: ct_available })
              })
              .catch(err => console.log(err))
          }
        })
          .catch();
      });
    }
  }
  handleDateChange = (date) => {
    this.setState({ start_date: date });
    const { formData } = this.state;
    formData['start_date'] = date;
    this.setState({ formData });
  }

  render() {
    const headers = [
      { label: "#", key: "uid" },
      { label: "Licence key", key: "licence_key" },
      { label: "Start date", key: "start_date" },
      { label: "End date", key: "end_date" },
      { label: "User", key: "user_name" },
    ];
    const { formData, submitted, formData_total_count, formData_available_count } = this.state;
    const { modal, start_date } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Licence key</h3>
            <div style={{ float: 'left', paddingTop: '15px' }}>
              <h5 className="">Total licence: <span class="bold-text">{formData_total_count}</span><br></br> Available licence: <span class="bold-text">{formData_available_count}</span></h5>
            </div>
            <div style={{ float: 'right' }}>

              {this.state.formData ?
                <CSVLink filename={"Licence.csv"}
                  className="btn btn-primary" style={{}} data={this.state.formData} headers={headers}>Export</CSVLink>
                : ''}
              <div style={{ display: 'inline' }}>
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  onClick={this.toggle}
                >{"Add Licence"}
                </Button>
                <Modal style={{
                  position: 'relative',
                  margin: '0 auto',
                  top: '28%',
                  width: '385px',
                }}
                  isOpen={modal}
                  toggle={this.toggle}
                  modalClassName={`ltr-support`}
                  className={`modal-dialog--primary modal-dialog--header`}
                >
                  <div className="modal__header">
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} style={{ marginBottom: '5px' }} />
                    <h4 className="text-modal  modal__title">{"Add Licence"}</h4>
                  </div>
                  <div className="modal__body">
                    <div className="form">
                      <ValidatorForm
                        className="material-form" id="lform"
                        onSubmit={this.handleSubmit} style={{ textAlign: "center" }}
                      >
                        <div className="form__form-group">
                          <div className="form__form-group-field">
                            <span className="form__form-group-label typography-message">Enter Licence Count </span>&nbsp;&nbsp;&nbsp;
                            <input
                              style={{ width: "auto" }}
                              type="text"
                              placeholder=""
                              required
                              onChange={this.handleChange}
                              name="licence"
                              id="licence"
                              validators={['required', 'matchRegexp:^[0-9]{1,6}$']}
                              errorMessages={['this field is required', 'Enter maximum 6 digit Number.']}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <div className="form__form-group-field priority">
                            <span className="form__form-group-label">Licence Start Date</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <DatePicker1
                              style={{ width: "auto" }}
                              dateFormat="dd-MM-yyyy"
                              selected={start_date}
                              name="start_date"
                              id="start_date"
                              onChange={this.handleDateChange}
                            />
                          </div>
                        </div>
                        <div className="form__form-group">
                          <div className="form__form-group-field">
                            <span className="form__form-group-label typography-message">Enter Number Days </span>&nbsp;&nbsp;&nbsp;
                            <input
                              style={{ width: "auto" }}
                              type="text"
                              placeholder=""
                              required
                              onChange={this.handleChange}
                              name="licence_days"
                              id="licence_days"
                              validators={['matchRegexp:^[0-9]{1,6}$']}
                              errorMessages={['Enter maximum 6 digit Number..']}
                            />
                          </div>
                        </div>
                      </ValidatorForm>
                    </div>
                  </div>
                  <ButtonToolbar className="modal__footer">
                    <Button color="primary" type="submit" onClick={this.handleLicenceSubmit}>Add</Button>
                    <Button className="modal_cancel" onClick={this.toggle}>Cancel</Button>{' '}
                  </ButtonToolbar>
                </Modal>
              </div>
            </div>

            {/* <div style={{ float: 'right' }}>
              <ValidatorForm
                className="material-form" id="pform"
                onSubmit={this.handleSubmit}
              >
                <input type="text"
                  onChange={this.handleChange}
                  className="material-form__field"
                  name="licence"
                  placeholder="Licence"
                  label="Licence"
                  // value={formData.licence}
                  validators={['matchRegexp:^[0-9]{1,6}$']}
                  errorMessages={['Enter maximum 6 digit Number..']}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    className="material-form__field"
                    onChange={this.handleStartDateChange}
                    name="start_date"
                    placeholder="Licence Start Date"
                    label="Licence Start Date"
                    value={this.state.start_date}
                    format="dd-MM-yyyy"
                  />
                </MuiPickersUtilsProvider>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                >
                  {
                    (submitted && 'Your form is submitted!')
                    || (!submitted && 'Submit')
                  }
                </Button>
              </ValidatorForm>
            </div> */}
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />

        </Row>
        <Row>
          <Col md={12}>

          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      {/* <SearchBar {...props.searchProps} /> */}
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          // sizePerPage: 25,
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Admin_list);
