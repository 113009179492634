import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Admin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      posts: [],
      user_id: '',
      formData: {
        sapcode: '',
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        mobile_no: '',
        designation: '',
        department: '',
        branch_name: '',
        username: '',
        password: '',
        vertical: '',
        date_of_birth: '',
        age: '',
        gender: '',
        blood_group: '',
        reporting_manager_sap_code: '',
        reporting_manager_name: '',
        reviewing_manager_sap_code: '',
        reviewing_manager_name: '',
        ho_poc_sap_code: '',
        ho_poc_name: '',
        department_head_sap_code: '',
        department_head_name: '',
        status: '',
        tenant_code: '',
        address: '',
      },
      submitted: false,
    }
    //this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {

    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      // alert(answer_array['3']);
      this.state.user_id = answer_array['3'];
      fetch(myConstClass.BASE_URL + '/user/getUpdateAdminInfo/' + answer_array['3'])
        .then(response => response.json())
        .then(response => { this.setState({ formData: response }) }).catch(err => console.log(err))

    }

    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }

  }
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }
  handleDateChange = (date) => {

    const { formData } = this.state;
    formData['date_of_birth'] = date;
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    var age = Math.abs(ageDate.getUTCFullYear() - 1970);//alert(age);
    formData['age'] = age;
    this.setState({ formData });
  };

  handleSubmit = () => {

    this.setState({ submitted: true }, () => {

      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL + `/user/add_admin/` + this.state.user_id, {
        method: 'POST',
        body: JSON.stringify($('#pform').serializeArray()),
      }).then(response => response.json()).then((response) => {
        if (response == true) {
          toast('User Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
          this.props.history.push('/forms/admin_list/');
        } else {
          toast('User Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
        }
      })
        .catch();
    });
  }

  handleStartDateChange = (date) => {
    const { formData } = this.state;
    formData['start_date'] = date;
    this.setState({ formData });
  };

  render() {
    const { formData, submitted } = this.state;
    const { posts } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            {/* {this.state.user_id?<h3 className="page-title">Edit Admin{posts.sap_code}</h3>:<h3 className="page-title">Add Admin{posts.sap_code}</h3>} */}
            {this.state.user_id ? <h3 className="page-title">Edit Tenant{posts.sap_code}</h3> : <h3 className="page-title">Add Tenant{posts.sap_code}</h3>}
            {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
          </Col>
        </Row>
        <Row>

          <Card>
            <CardBody>
              <div className="card__title">
                {/* <h5 className="bold-text">{t('forms.floating_labels_form.animated_line_inputs')}</h5>
           */}
                {/* <h5 className="bold-text">Add Admin/Employee {posts.sap_code}</h5> */}
                {/* <h5 className="subhead">Material fields with a property <span className="red-text">label</span></h5> */}
              </div>
              <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />

              </div>

              <ValidatorForm
                className="material-form" id="pform"
                onSubmit={this.handleSubmit}
              >
                <Row>
                  <Col md={6} lg={6}>
                    <TextValidator
                      onChange={this.handleChange}
                      name="firstname"
                      placeholder="First name"
                      label="First name"
                      className="material-form__field"
                      value={formData.firstname}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="middlename"
                      placeholder="Middle name"
                      label="Middle name"
                      value={formData.middlename}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="lastname"
                      placeholder="Last name"
                      label="Last name"
                      value={formData.lastname}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="email"
                      placeholder="Email"
                      label="Email"
                      value={formData.email}
                      validators={['required', 'isEmail']}
                      errorMessages={['this field is required', 'Enter valid email address']}
                    />

                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="address"
                      placeholder="Address"
                      label="Address"
                      value={formData.address}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="username"
                      placeholder="Username"
                      label="Username"
                      value={formData.username}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />

                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="password"
                      placeholder="Password"
                      label="Password"
                      value={formData.password}
                      // validators={['required', 'matchRegexp:^[0-9]{6}$']}
                      // errorMessages={['this field is required', 'Enter 6 digit Number..']}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />


                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="mobile_no"
                      placeholder="Mobile No"
                      label="Mobile No"
                      value={formData.mobile_no}
                      validators={['required', 'matchRegexp:^[0-9]{10}$']}
                      errorMessages={['this field is required', 'Enter 10 digit Number..']}
                    />
                    <br />
                    <FormControl className="material-form__field">
                      <InputLabel htmlFor="age-simple">Status</InputLabel>
                      <Select
                        value={formData.status}
                        onChange={this.handleChange}
                        inputProps={{
                          name: 'status',
                          id: 'status',
                        }}
                      >
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={2}>Inactive</MenuItem>
                        <MenuItem value={3}>Suspended</MenuItem>
                      </Select>
                    </FormControl>
                    <br />
                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="tenant_code"
                      placeholder="Tenant code"
                      label="Tenant code"
                      value={formData.tenant_code}
                      validators={['required', 'matchRegexp:^[0-9]{1,6}$']}
                      errorMessages={['this field is required', 'Enter maximum 6 digit Number..']}
                    />
                    <br />
                    {/* added on - 3-aug-2022 */}
                    {this.state.user_id ?
                      <InputLabel htmlFor="pre_licence">Total Licence - 0</InputLabel>
                      : ""}

                    {this.state.user_id ?
                      "" : <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          className="material-form__field"
                          onChange={this.handleStartDateChange}
                          name="start_date"
                          placeholder="Licence Start Date"
                          label="Licence Start Date"
                          // value={this.state.date}
                          format="dd-MM-yyyy"
                        />
                      </MuiPickersUtilsProvider>
                    }
                    <br />
                    {this.state.user_id ?
                      "" : <div>
                        <TextValidator
                          onChange={this.handleChange}
                          className="material-form__field"
                          name="licence"
                          placeholder="Licence"
                          label="Licence"
                          // value={formData.licence}
                          validators={['matchRegexp:^[0-9]{1,6}$']}
                          errorMessages={['Enter maximum 6 digit Number..']}
                        /></div>
                    }
                    <br />

                    <br />
                  </Col>

                  <Col md={6} lg={6}>
                    {/* <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="mobile_no"
                      placeholder="Mobile No"
                      label="Mobile No"
                      value={formData.mobile_no}
                      validators={['required', 'matchRegexp:^[0-9]{10}$']}
                      errorMessages={['this field is required', 'Enter 10 digit Number..']}
                    />
                    <br /> */}
                    {/* <div>
                      <FormControl className="material-form__field">
                        <InputLabel htmlFor="gender">Gender</InputLabel>
                        <Select
                          value={formData.gender}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'gender',
                            id: 'gender',
                          }}
                        >
                          <MenuItem value={1}>Male</MenuItem>
                          <MenuItem value={2}>Female</MenuItem>
                          <MenuItem value={3}>Others</MenuItem>
                        </Select>
                      </FormControl>
                    </div> */}
                    {/* <div>
                      <FormControl className="material-form__field">
                        <InputLabel htmlFor="blood_group">Blood Group</InputLabel>
                        <Select
                          value={formData.blood_group}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'blood_group',
                            id: 'blood_group',
                          }}
                        >
                          <MenuItem value={1}>A+</MenuItem>
                          <MenuItem value={2}>B+</MenuItem>
                          <MenuItem value={3}>O+</MenuItem>
                          <MenuItem value={4}>AB+</MenuItem>
                          <MenuItem value={5}>A-</MenuItem>
                          <MenuItem value={6}>B-</MenuItem>
                          <MenuItem value={7}>O-</MenuItem>
                          <MenuItem value={8}>AB-</MenuItem>
                        </Select>
                      </FormControl>
                    </div> */}
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="material-form__field"
                        onChange={this.handleDateChange}
                        name="date_of_birth"
                        placeholder="Date of Birth"
                        label="Date of Birth"
                        value={this.state.date}
                        format="dd-MM-yyyy"
                      />
                    </MuiPickersUtilsProvider>

                    <TextValidator
                      onChange={this.handleChange}
                      className="material-form__field"
                      name="age"
                      placeholder="Age"
                      label="Age"
                      value={formData.age}
                      validators={['required', 'matchRegexp:^[0-9]{1,3}$']}
                      errorMessages={['this field is required', 'Enter maximum 3 digit Number..']}
                    />
                    <br /> */}

                    <div>
                      {/* <FormControl className="material-form__field">
                        <InputLabel htmlFor="age-simple">Status</InputLabel>
                        <Select
                          value={formData.status}
                          onChange={this.handleChange}
                          inputProps={{
                            name: 'status',
                            id: 'status',
                          }}
                        >
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={2}>Inactive</MenuItem>
                          <MenuItem value={3}>Suspended</MenuItem>
                        </Select>
                      </FormControl> */}

                      {/* added on - 5-may-2022 */}
                      {/* <TextValidator
                        onChange={this.handleChange}
                        className="material-form__field"
                        name="tenant_code"
                        placeholder="Tenant code"
                        label="Tenant code"
                        value={formData.tenant_code}
                        validators={['required', 'matchRegexp:^[0-9]{1,6}$']}
                        errorMessages={['this field is required', 'Enter maximum 6 digit Number..']}
                      /> */}
                      <br />

                    </div>
                   
                    <br />
                    <br />
                    <br />
                    <br />
                    <div></div>
                    {/* {this.state.user_id ?
                      "" : <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          className="material-form__field"
                          onChange={this.handleStartDateChange}
                          name="start_date"
                          placeholder="Licence Start Date"
                          label="Licence Start Date"
                          // value={this.state.date}
                          format="dd-MM-yyyy"
                        />
                      </MuiPickersUtilsProvider>
                    } */}
                  </Col>
                </Row>
                <br />

                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                >
                  {
                    (submitted && 'Your form is submitted!')
                    || (!submitted && 'Submit')
                  }
                </Button>

              </ValidatorForm>
            </CardBody>
          </Card>

          {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'admin', // a unique identifier for this form
})(withTranslation('common')(Admin));
