import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import Gallery from 'react-grid-gallery';
import "video-react/dist/video-react.css";
import { Player } from 'video-react';
import ReactAudioPlayer from 'react-audio-player';
import 'react-photo-view/dist/react-photo-view.css';
import { Image } from 'antd';
import '../../../antd.css';

const IMAGES =
  [{
    src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
    thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    isSelected: true,
    caption: "After Rain (Jeshu John - designerspics.com)"
  },
  {
    src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
    thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    tags: [{ value: "Ocean", title: "Ocean" }, { value: "People", title: "People" }],
    caption: "Boats (Jeshu John - designerspics.com)"
  },
  {
    src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
    thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212
  }]

class Evidence_view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      contacts: [],
      images: [],
      videos: [],
      audios: [],
      rotation: 0,
    }
    this.flag = 0;
    this.rotateImg = this.rotateImg.bind(this);
  }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      fetch(myConstClass.BASE_URL + '/user/getUserInfo/' + answer_array['3'])
        .then(response => response.json())
        .then(response => this.setState({ posts: response }))
        .catch(err => console.log(err))

      fetch(myConstClass.BASE_URL + '/user/getUserEmergencynfo/' + answer_array['3'])
        .then(response => response.json())
        .then(response => this.setState({ contacts: response }))
        .catch(err => console.log(err))

      fetch(myConstClass.BASE_URL + '/user/getUserImages/' + answer_array['3'])
        .then(response => response.json())
        .then(response => this.setState({ images: response }))
        .catch(err => console.log(err))

      fetch(myConstClass.BASE_URL + '/user/getUserVideos/' + answer_array['3'])
        .then(response => response.json())
        .then(response => this.setState({ videos: response }))
        .catch(err => console.log(err))

      fetch(myConstClass.BASE_URL + '/user/getUserAudios/' + answer_array['3'])
        .then(response => response.json())
        .then(response => this.setState({ audios: response }))
        .catch(err => console.log(err))
    }
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }

  rotateImg() {
    let newRotation = this.state.rotation + 90;
    if (newRotation >= 360) {
      newRotation = - 360;
    }
    this.setState({
      rotation: newRotation,
    })
    document.querySelector("#img1").style.transform = "rotate(" + newRotation + "deg)";
  }

  render() {
    const { posts, images, videos, audios, contacts } = this.state;
    const { rotation } = this.state;

    if (posts.last_name)
      posts.last_name = posts.last_name;
    else
      posts.last_name = '';
    const heading = posts.first_name + ' ' + posts.last_name + '(' + posts.mobile_no + ')';
    var google_map_link = '';
    if (posts.user_lat) {
      // google_map_link ='https://maps.google.com/?q='+ posts.user_lat + ',' + posts.user_long  ;
      google_map_link = 'https://maps.google.com/maps?q=' + posts.user_lat + ',' + posts.user_long + '&hl=en&z=14&output=embed';
    }

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Evidence - {heading}
            </h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={5} lg={5}>
                    <h3>User Detail</h3>
                    <div style={{ 'padding-top': '25px', 'font-size': '16px', 'color': '#646777' }}><b>Name:</b> {posts.first_name} {posts.last_name}</div>
                    <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Email:</b> {posts.email}</div>
                    <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Mobile Number:</b> {posts.mobile_no}</div>
                    <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Latitude:</b> {posts.user_lat}</div>
                    <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Longitude:</b> {posts.user_long}</div>
                    <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Gender:</b> {posts.gender_value}</div>
                    <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Blood Group:</b> {posts.blood_group_value}</div>
                    <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Critical Illness:</b> {posts.critical_illness_value}</div>
                    <div style={{ 'padding-top': '5px', 'font-size': '16px', 'color': '#646777' }}><b>Date/Time:</b> {posts.date_time}</div>
                    {/* <div style={{'padding-top': '5px','font-size': '16px','color':'#646777'}}><b>Address:</b> {posts.city}</div> */}
                  </Col>
                  <Col md={7} lg={7}>
                    {google_map_link != "" ?
                      <div  >
                        <iframe src={google_map_link} width={'100%'} height={350} frameborder="0" style={{ border: '0' }} ></iframe>
                      </div> : ""}
                  </Col>
                </Row>
                <hr />
                {posts.content_type != 4 ?
                  <div>
                    <Row>
                      <Col md={12} lg={12}>
                        <h3>
                          Images
                        </h3>
                        {images != "" ?
                          <Image.PreviewGroup>
                            {images.map((img, index) =>
                              <span style={{ marginRight: '15px' }}>
                                <Image
                                  width={250}
                                  height={250}
                                  src={img.file_path}
                                /></span>
                            )}
                          </Image.PreviewGroup>
                          : <h5 style={{ "margin-bottom": '5px' }}> No record found.</h5>}
                        {/* <Gallery images={images} /> */}
                        {/* <Image
                          width={200}
                          src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                        /> */}
                        <div>
                        </div>
                        {/* <button onClick={this.rotateImg} >Rotate Image</button>
                        <div class="ReactGridGallery_tile-viewport" style={{ width: '271px', height: '180px', overflow: 'hidden' }}>
                          <img id="img1" src="https://captainindia.anekalabs.com/backend/uploads/multimedia/thumbnail/711660110738917165793_thumb.jpg" alt="" style={{ cursor: 'pointer', width: '271px', height: '180px', marginLeft: '0px', marginTop: '0px' }} />
                        </div> */}
                      </Col>
                    </Row>

                    <hr />
                    <Row>
                      <Col md={4} lg={4}>
                        <h3>
                          Video
                        </h3>
                      </Col>
                      <Col md={8} lg={8}>
                        <h3 style={{ 'margin-left': '15px' }}>
                          Audio
                        </h3>
                      </Col>
                    </Row>
                  </div>
                  : ""}
                <Row>
                  {posts.content_type != 4 ?
                    <Col md={4} lg={4} style={{ "margin-bottom": '10px' }}>
                      {videos != "" ?
                        <span>
                          {videos.map((video, index) =>
                            <Player
                              playsInline
                              poster="/assets/poster.png"
                              src={video.file_path}
                              width={320}
                              height={212}
                              fluid={false}
                            />
                          )}
                        </span>
                        :
                        <h5 style={{ "margin-bottom": '5px' }}> No record found.</h5>
                      }
                    </Col>
                    : ""}
                  <Col md={8} lg={8} style={{ "margin-bottom": '10px' }}>
                    {posts.content_type != 4 ?
                      <Col md={12} lg={12}>
                        {audios != "" ?
                          <span>
                            {audios.map((audio, index) =>
                              <ReactAudioPlayer
                                src={audio.file_path}
                                controls
                              />)}
                          </span>
                          :
                          <h5 style={{ "margin-bottom": '5px' }}> No record found.</h5>
                        }
                      </Col>
                      : ""}
                    <br />
                    <Col md={12} lg={12}>
                      <h3>Emergency Contacts</h3><br />
                      {contacts.map((audio, index) =>
                        <div><h5 style={{ "margin-bottom": '5px' }}>{index + 1}.&nbsp;&nbsp;{audio.first_name + ' ' + audio.last_name}&nbsp;&nbsp;{'|'}&nbsp;&nbsp;{'Mobile No: ' + audio.mobile_no}</h5></div>
                      )}
                    </Col>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Evidence_view);
