import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody, ButtonToolbar  } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { Field, reduxForm } from 'redux-form';
import FileUploadDefault from './components/FileUploadDefault';
import FileUploadCustomHeight from './components/FileUploadCustomHeight';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import CsvDownloader from 'react-csv-downloader';
const file_link = `${process.env.PUBLIC_URL}/Location_zip.zip`;
const loader = `${process.env.PUBLIC_URL}/loader.gif`;
const columns = [{
  id: 'id',
  displayName: 'ID'
}, {
  id: 'branch_code',
  displayName: 'Branch Code'
},{
  id: 'branch_name',
  displayName: 'Branch Name'
},{
  id: 'region_name',
  displayName: 'District'
},{
  id: 'zone_name',
  displayName: 'State'
},{
  id: 'state_name',
  displayName: 'Country'
}];

class Location_import extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      posts:'',
      dusers:''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
    if(!localStorage.getItem('session_id')){
      console.log('History:',this.props);      
      this.props.history.push('/log_in');
  }
  }
  handleSubmit = () => {
    alert(JSON.stringify($('#pform').serializeArray()));
  }
  onChangeHandler=event=>{

    console.log(event.target.files[0]);
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })

}
onClickHandler = () => {
  const data = new FormData() 
  data.append('file', this.state.selectedFile);
  data.append('session_id', localStorage.getItem('session_id'));
  $('#loader').show();
  fetch(myConstClass.BASE_URL+`/user/location_import`, {
    method: 'POST',
    body: data,
  }).then(response => response.json())
  .then(response =>this.setState({posts: response}),$('#loader').hide())

  fetch(myConstClass.BASE_URL+`/user/getLocationDeclinedUser`, {
    method: 'POST',
    body: data,
  }).then(response => response.json())
  .then(response =>this.setState({dusers: response}))

}

onSaveClickHandler = () => {
  const data = new FormData() 
  data.append('file', this.state.selectedFile);
  data.append('session_id', localStorage.getItem('session_id'));
  fetch(myConstClass.BASE_URL+`/user/save_Location_import_data`, {
    method: 'POST',
    body: data,
  }).then(response =>  response.json()).then((response) => {
    if(response){
      toast('User Location Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
      this.props.history.push('/forms/locations/');
    }else{
      toast('User Location Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
    }
  })
  .catch();


}

  render() {
   
    return (
      <Container>
    <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
    <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    <Row>
      <Col md={12}>
        <h3 className="page-title">Location Bulk Import</h3>
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
      </Col>
    </Row>
    <Card>
      <CardBody>
      
         { this.state.posts ?
         
         <div>
           <Row>
           
           <Col md={10}>
           <h5>Insert : {this.state.posts.icnt} &nbsp;&nbsp; Update : {this.state.posts.ucnt} &nbsp;&nbsp; Error : {this.state.posts.ecnt}</h5>
           </Col>
           <Col md={2}>
      <CsvDownloader
        filename="DeclinedUser"
        separator=","
        columns={columns}
        datas={this.state.dusers}
        className="btn btn-primary btn-block col-md-2"
        text="View Declined User" />
   </Col>   
   </Row>
           <hr/>
          <MDBDataTable
      striped
      hover
      data={this.state.posts}
    />
    <Col md={6}>
  <button type="button" class="btn btn-primary btn-block col-md-4" onClick={this.onSaveClickHandler}>Save</button>
    
    </Col>
    </div>
  : <form className="form" id="pform" onSubmit={this.handleSubmit}>
      
  <Col md={3}>
  <input type="file" name="file" onChange={this.onChangeHandler}/>   
  </Col>
 
    <Col md={6}>
    <Row>
  <button type="button" style={{width: '40%'}} class="btn btn-primary btn-block col-md-4" onClick={this.onClickHandler}>Upload</button>
  <img src={loader}  id="loader" style={{width: '12%',display:'none'}}  />
  </Row>
  </Col>
  <Col md={3}>
  <a href={file_link} style={{float:'right'}}>
  <Button color="primary" type="button" className="previous">Download Sample File</Button>
  </a>
  </Col> 
        
        </form> }
  
      </CardBody>
      </Card>
  </Container>
    )
  }
}

Location_import.propTypes = {
  //handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
export default reduxForm({
  form: 'Location_import', // a unique identifier for this form
})(withTranslation('common')(Location_import));

