import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import AnimatedLineFormWithLabels from './components/AnimatedLineFormWithLabels';
import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      alert('in..'+e.target.value);
      input.onChange(e.target.value);
    }}

  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Location_add extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      user_id:'',
      posts: [],
      states:[],
      countries:[],
      districts:[],
      formData: {
        branchcode: '',
        branchname: '',
        country: '',
        states: '',
        district:'',
      },
    submitted: false,
    isButtonDisabled:true,
    isButtonDisabled1:true,
    }
    this.changeStates = this.changeStates.bind(this);
  }
  componentDidMount(){
   
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
      //alert(answer_array['3']);
      this.state.user_id = answer_array['3'];
      fetch(myConstClass.BASE_URL+'/user/getUpdateUserLocationInfo/'+answer_array['3'])
  .then(response => response.json())
  .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))
      
    }
  //   fetch(myConstClass.BASE_URL+'/user/getStates/')
  // .then(response => response.json())
  // .then(response =>{this.setState({states: response})}).catch(err => console.log(err))

  fetch(myConstClass.BASE_URL+'/user/getCountries/')
  .then(response => response.json())
  .then(response =>{this.setState({countries: response})}).catch(err => console.log(err))

  // fetch(myConstClass.BASE_URL+'/user/getDistrict/')
  // .then(response => response.json())
  // .then(response =>{this.setState({districts: response})}).catch(err => console.log(err))

  if(!localStorage.getItem('session_id')){
    console.log('History:',this.props);      
    this.props.history.push('/log_in');
}

  }
  changeStates = (event)=>{
    //alert(event.target.value);
    fetch(myConstClass.BASE_URL+'/user/getStates/'+event.target.value)
  .then(response => response.json())
  .then(response =>{
    this.setState({states: response})
   // if(response['0']['StateID'] !=0){   
    this.setState({isButtonDisabled:false})
   // }
  
  }).catch(err => console.log(err))
  

  const { formData } = this.state;
  formData[event.target.name] = event.target.value;
  this.setState({ formData });//alert($('#states').length);
   
    //$('#states').removeAttr('disabled');
  }
  changeDistrict = (event)=>{
    //alert(event.target.value);
    fetch(myConstClass.BASE_URL+'/user/getDistrict/'+event.target.value)
  .then(response => response.json())
  .then(response =>{
    this.setState({districts: response})
   // if(response['0']['id'] !=0){    
    this.setState({isButtonDisabled1:false})
   // }
  
  }).catch(err => console.log(err))

  const { formData } = this.state;
  formData[event.target.name] = event.target.value;
  this.setState({ formData });
   
   // $('#districts').removeAttr('disabled');
  }
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
}
handleDateChange = (date) => {
  
  this.setState({ date });
};

handleSubmit = () => {
 
  this.setState({ submitted: true }, () => {
    
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL+`/user/addLocation/`+this.state.user_id, {
              method: 'POST',
              body: JSON.stringify($('#pform').serializeArray()),
            }).then(response =>  response.json()).then((response) => {
              if(response == true){
                toast('Location Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                this.props.history.push('/forms/locations/');
              }else{
                toast('User Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
  });
}


  render() {
    const { formData, submitted,states,districts,countries,isButtonDisabled,isButtonDisabled1 } = this.state;
    const { posts} = this.state;
    return (
    <Container>
    <Row>
      <Col md={12}>
      {this.state.user_id ?
        <h3 className="page-title">Edit Location{posts.sap_code}</h3>:<h3 className="page-title">Add Location{posts.sap_code}</h3>}
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
      </Col>
    </Row>
    <Row>   
     
    <Card>
      <CardBody>
        <div className="card__title">
          {/* <h5 className="bold-text">{t('forms.floating_labels_form.animated_line_inputs')}</h5>
           */}
        {/* <h5 className="bold-text">Add Admin/Employee {posts.sap_code}</h5> */}
        {/* <h5 className="subhead">Material fields with a property <span className="red-text">label</span></h5> */}
        </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
        </div>
        
        <ValidatorForm
                className="material-form" id="pform" 
                onSubmit={this.handleSubmit}
            >      
     
          <Row>
          
        <Col md={6} lg={6}>
            
            <TextValidator
             onChange={this.handleChange}
             name="branchcode"          
             label="Branch Code"
             value={formData.branchcode}
             validators={['required']}
             errorMessages={['this field is required']}
             className="material-form__field"
            />            
         <br/>  
         <TextValidator
            onChange={this.handleChange}
              name="branchname"
              placeholder="Branch name"
              label="Branch name"
              className="material-form__field"
              value={formData.branchname}
             validators={['required']}
             errorMessages={['this field is required']}
            />
             <br/>
             <FormControl  className="material-form__field">
        <InputLabel htmlFor="blood_group">Country</InputLabel>
        <Select
          value={formData.country}
          onChange={this.changeStates}
          inputProps={{
            name: 'country',
            id: 'country',
          }}
        >
           {countries.map((cell, i) => {
                 return(<MenuItem value={cell.id}>{cell.country_name}</MenuItem>)
            })}

        </Select>
      </FormControl>
             {/* <Field
              name="country"
              component={renderTextField}
              value={formData.country}
              select
              label="Select Country"
              onchange={this.changeStates}
            >
            {countries.map((cell, i) => {
              return(<MenuItem className="material-form__option" value={cell.id} >{cell.country_name}</MenuItem>)
            })}

            </Field>         */}
         
          <div>
          
          </div>
          {/* </Col>
         
          <Col md={6} lg={6}> */}
          
          <div>
          <FormControl  className="material-form__field">
        <InputLabel htmlFor="blood_group">State</InputLabel>
        <Select
          value={formData.states}
          onChange={this.changeDistrict}
          disabled={ isButtonDisabled }
          inputProps={{
            name: 'states',
            id: 'states',
            
          }}
        >
        
           {states.map((cell, i) => {
                 return(<MenuItem value={cell.StateID}>{cell.StateName}</MenuItem>)
            })}

        </Select>
      </FormControl>
          {/* <Field
              name="states"
              component={renderTextField}
              value={formData.states}
              select
              label="Select State"
              
            >
            {states.map((cell, i) => {
              return(<MenuItem className="material-form__option" value={cell.StateID}>{cell.StateName}</MenuItem>)
            })}

            </Field> */}
          </div>
          <div>
          <FormControl  className="material-form__field">
        <InputLabel htmlFor="blood_group">District</InputLabel>
        <Select
          value={formData.district}
          onChange={this.handleChange}
          disabled={ isButtonDisabled1 }
          inputProps={{
            name: 'district',
            id: 'district',
            
          }}
        >
           {districts.map((cell, i) => {
                 return(<MenuItem value={cell.id}>{cell.name}</MenuItem>)
            })}

        </Select>
      </FormControl>
          {/* <Field
              name="district"
              component={renderTextField}
              value={formData.district}
              select
              label="Select District"
              
            >
            {districts.map((cell, i) => {
                 return(<MenuItem className="material-form__option" value={cell.id}>{cell.name}</MenuItem>)
            })}

            </Field> */}
          </div>
          </Col>
          </Row>
          <br />
          <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Your form is submitted!')
                        || (!submitted && 'Submit')
                    }
                </Button>
               
          </ValidatorForm>
          </CardBody>
    </Card>
 
      {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
    </Row>
  </Container>
    )

  }

}



export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(Location_add));
