export default [
  {
    name: 'Nikolay Morris',
    subject: 'Breakfast agreeable incommode departure it an.',
    preview: 'By ignorant at on wondered relation. Enough at tastes really so cousin am of. '
    + 'Extensive therefore supported by.. ',
    attach: true,
    favorite: false,
    time: '04:23 PM',
    unread: true,
  },
  {
    name: 'Anna Boo',
    subject: 'By ignorant at on wondered relation.',
    preview: 'Enough at tastes really so cousin am of. Extensive therefore supported by extremity of '
    + 'contented. Is pursuit compac nviterns…',
    attach: false,
    favorite: true,
    time: '09:54 AM',
    unread: false,
  },
  {
    name: 'Nikolay Morris',
    subject: 'Breakfast agreeable incommode departure it an.',
    preview: 'By ignorant at on wondered relation. Enough at tastes really so cousin am of. '
    + 'Extensive therefore supported by.. ',
    attach: true,
    favorite: true,
    time: 'July 12',
    unread: true,
  },
  {
    name: 'Anna Boo',
    subject: 'By ignorant at on wondered relation. ',
    preview: 'Enough at tastes really so cousin am of. Extensive therefore supported by extremity of '
    + 'contented. Is pursuit compact  inviterns… ',
    attach: false,
    favorite: false,
    time: 'July 12',
    unread: false,
  },
  {
    name: 'Aspirity Team',
    subject: 'Death there mirth way the noisy merit.',
    preview: 'Piqued shy spring nor six though mutual living ask extent. Replying of dashwood advanced '
    + 'ladyship smallest disposal or...',
    attach: false,
    favorite: false,
    time: 'July 10 ',
    unread: false,
  },
  {
    name: 'Leonardo Crown',
    subject: 'Attempt offices own improve now see.',
    preview: 'Called person are around county talked her esteem. Those fully these way nay thing seems. ',
    attach: false,
    favorite: true,
    time: 'July 10 ',
    unread: false,
  },
  {
    name: 'Cortney White',
    subject: 'Him had wound use found hoped.',
    preview: 'Of distrusts immediate enjoyment curiosity do. Marianne numerous saw thoughts the humoured. ',
    attach: false,
    favorite: false,
    time: 'July 07',
    unread: false,
  },
  {
    name: 'Anna Boo',
    subject: 'By ignorant at on wondered relation.',
    preview: 'Enough at tastes really so cousin am of. Extensive therefore supported by extremity of '
    + 'contented. Is pursuit compact  inviterns… ',
    attach: false,
    favorite: false,
    time: 'July 06',
    unread: true,
  },
  {
    name: 'Aspirity Team',
    subject: 'Death there mirth way the noisy merit.',
    preview: 'Piqued shy spring nor six though mutual living ask extent. Replying of dashwood advanced '
    + 'ladyship smallest disposal or...',
    attach: false,
    favorite: false,
    time: 'July 05 ',
    unread: false,
  },
  {
    name: 'Leonardo Crown',
    subject: 'Attempt offices own improve now see.',
    preview: 'Called person are around county talked her esteem. Those fully these way nay thing seems.',
    attach: false,
    favorite: true,
    time: 'July 05',
    unread: false,
  },
  {
    name: 'Nikolay Morris',
    subject: 'Breakfast agreeable incommode departure it an.',
    preview: 'By ignorant at on wondered relation. Enough at tastes really so cousin am of. Extensive '
    + 'herefore supported by.. ',
    attach: true,
    favorite: false,
    time: '04:23 PM',
    unread: true,
  },
  {
    name: 'Anna Boo',
    subject: 'By ignorant at on wondered relation.',
    preview: 'Enough at tastes really so cousin am of. Extensive therefore supported by extremity of '
    + 'contented. Is pursuit compac nviterns…',
    attach: false,
    favorite: true,
    time: '09:54 AM',
    unread: false,
  },
  {
    name: 'Nikolay Morris',
    subject: 'Breakfast agreeable incommode departure it an.',
    preview: 'By ignorant at on wondered relation. Enough at tastes really so cousin am of. Extensive '
    + 'therefore supported by.. ',
    attach: true,
    favorite: true,
    time: 'July 12',
    unread: true,
  },
  {
    name: 'Anna Boo',
    subject: 'By ignorant at on wondered relation. ',
    preview: 'Enough at tastes really so cousin am of. Extensive therefore supported by extremity of '
    + 'contented. Is pursuit compact  inviterns… ',
    attach: false,
    favorite: false,
    time: 'July 12',
    unread: false,
  },
  {
    name: 'Aspirity Team',
    subject: 'Death there mirth way the noisy merit.',
    preview: 'Piqued shy spring nor six though mutual living ask extent. Replying of dashwood advanced '
    + 'ladyship smallest disposal or...',
    attach: false,
    favorite: false,
    time: 'July 10 ',
    unread: false,
  },
  {
    name: 'Leonardo Crown',
    subject: 'Attempt offices own improve now see.',
    preview: 'Called person are around county talked her esteem. Those fully these way nay thing seems. ',
    attach: false,
    favorite: true,
    time: 'July 10 ',
    unread: false,
  },
  {
    name: 'Cortney White',
    subject: 'Him had wound use found hoped.',
    preview: 'Of distrusts immediate enjoyment curiosity do. Marianne numerous saw thoughts the humoured. ',
    attach: false,
    favorite: false,
    time: 'July 07',
    unread: false,
  },
  {
    name: 'Anna Boo',
    subject: 'By ignorant at on wondered relation.',
    preview: 'Enough at tastes really so cousin am of. Extensive therefore supported by extremity of '
    + 'contented. Is pursuit compact  inviterns… ',
    attach: false,
    favorite: false,
    time: 'July 06',
    unread: true,
  },
  {
    name: 'Aspirity Team',
    subject: 'Death there mirth way the noisy merit.',
    preview: 'Piqued shy spring nor six though mutual living ask extent. Replying of dashwood advanced '
    + 'ladyship smallest disposal or...',
    attach: false,
    favorite: false,
    time: 'July 05 ',
    unread: false,
  },
  {
    name: 'Leonardo Crown',
    subject: 'Attempt offices own improve now see.',
    preview: 'Called person are around county talked her esteem. Those fully these way nay thing seems.',
    attach: false,
    favorite: true,
    time: 'July 05',
    unread: false,
  },
  {
    name: 'Nikolay Morris',
    subject: 'Breakfast agreeable incommode departure it an.',
    preview: 'By ignorant at on wondered relation. Enough at tastes really so cousin am of. Extensive '
    + 'therefore supported by.. ',
    attach: true,
    favorite: false,
    time: '04:23 PM',
    unread: true,
  },
  {
    name: 'Anna Boo',
    subject: 'By ignorant at on wondered relation.',
    preview: 'Enough at tastes really so cousin am of. Extensive therefore supported by extremity of '
    + 'contented. Is pursuit compac nviterns…',
    attach: false,
    favorite: true,
    time: '09:54 AM',
    unread: false,
  },
  {
    name: 'Nikolay Morris',
    subject: 'Breakfast agreeable incommode departure it an.',
    preview: 'By ignorant at on wondered relation. Enough at tastes really so cousin am of. Extensive '
    + 'therefore supported by.. ',
    attach: true,
    favorite: true,
    time: 'July 12',
    unread: true,
  },
  {
    name: 'Anna Boo',
    subject: 'By ignorant at on wondered relation. ',
    preview: 'Enough at tastes really so cousin am of. Extensive therefore supported by extremity of '
    + 'contented. Is pursuit compact  inviterns… ',
    attach: false,
    favorite: false,
    time: 'July 12',
    unread: false,
  },
  {
    name: 'Aspirity Team',
    subject: 'Death there mirth way the noisy merit.',
    preview: 'Piqued shy spring nor six though mutual living ask extent. Replying of dashwood advanced '
    + 'ladyship smallest disposal or...',
    attach: false,
    favorite: false,
    time: 'July 10 ',
    unread: false,
  },
  {
    name: 'Leonardo Crown',
    subject: 'Attempt offices own improve now see.',
    preview: 'Called person are around county talked her esteem. Those fully these way nay thing seems. ',
    attach: false,
    favorite: true,
    time: 'July 10 ',
    unread: false,
  },
  {
    name: 'Cortney White',
    subject: 'Him had wound use found hoped.',
    preview: 'Of distrusts immediate enjoyment curiosity do. Marianne numerous saw thoughts the humoured. ',
    attach: false,
    favorite: false,
    time: 'July 07',
    unread: false,
  },
  {
    name: 'Anna Boo',
    subject: 'By ignorant at on wondered relation.',
    preview: 'Enough at tastes really so cousin am of. Extensive therefore supported by extremity of '
    + 'contented. Is pursuit compact  inviterns… ',
    attach: false,
    favorite: false,
    time: 'July 06',
    unread: true,
  },
  {
    name: 'Aspirity Team',
    subject: 'Death there mirth way the noisy merit.',
    preview: 'Piqued shy spring nor six though mutual living ask extent. Replying of dashwood advanced '
    + 'ladyship smallest disposal or...',
    attach: false,
    favorite: false,
    time: 'July 05 ',
    unread: false,
  },
  {
    name: 'Leonardo Crown',
    subject: 'Attempt offices own improve now see.',
    preview: 'Called person are around county talked her esteem. Those fully these way nay thing seems.',
    attach: false,
    favorite: true,
    time: 'July 05',
    unread: false,
  },
  {
    name: 'Nikolay Morris',
    subject: 'Breakfast agreeable incommode departure it an.',
    preview: 'By ignorant at on wondered relation. Enough at tastes really so cousin am of. Extensive '
    + 'therefore supported by.. ',
    attach: true,
    favorite: false,
    time: '04:23 PM',
    unread: true,
  },
  {
    name: 'Anna Boo',
    subject: 'By ignorant at on wondered relation.',
    preview: 'Enough at tastes really so cousin am of. Extensive therefore supported by extremity of '
    + 'contented. Is pursuit compac nviterns…',
    attach: false,
    favorite: true,
    time: '09:54 AM',
    unread: false,
  },
  {
    name: 'Nikolay Morris',
    subject: 'Breakfast agreeable incommode departure it an.',
    preview: 'By ignorant at on wondered relation. Enough at tastes really so cousin am of. Extensive '
    + 'therefore supported by.. ',
    attach: true,
    favorite: true,
    time: 'July 12',
    unread: true,
  },
  {
    name: 'Anna Boo',
    subject: 'By ignorant at on wondered relation. ',
    preview: 'Enough at tastes really so cousin am of. Extensive therefore supported by extremity of '
    + 'contented. Is pursuit compact  inviterns… ',
    attach: false,
    favorite: false,
    time: 'July 12',
    unread: false,
  },
  {
    name: 'Aspirity Team',
    subject: 'Death there mirth way the noisy merit.',
    preview: 'Piqued shy spring nor six though mutual living ask extent. Replying of dashwood advanced '
    + 'ladyship smallest disposal or...',
    attach: false,
    favorite: false,
    time: 'July 10 ',
    unread: false,
  },
  {
    name: 'Leonardo Crown',
    subject: 'Attempt offices own improve now see.',
    preview: 'Called person are around county talked her esteem. Those fully these way nay thing seems. ',
    attach: false,
    favorite: true,
    time: 'July 10 ',
    unread: false,
  },
  {
    name: 'Cortney White',
    subject: 'Him had wound use found hoped.',
    preview: 'Of distrusts immediate enjoyment curiosity do. Marianne numerous saw thoughts the humoured. ',
    attach: false,
    favorite: false,
    time: 'July 07',
    unread: false,
  },
  {
    name: 'Anna Boo',
    subject: 'By ignorant at on wondered relation.',
    preview: 'Enough at tastes really so cousin am of. Extensive therefore supported by extremity of '
    + 'contented. Is pursuit compact  inviterns… ',
    attach: false,
    favorite: false,
    time: 'July 06',
    unread: true,
  },
  {
    name: 'Aspirity Team',
    subject: 'Death there mirth way the noisy merit.',
    preview: 'Piqued shy spring nor six though mutual living ask extent. Replying of dashwood advanced '
    + 'ladyship smallest disposal or...',
    attach: false,
    favorite: false,
    time: 'July 05 ',
    unread: false,
  },
  {
    name: 'Leonardo Crown',
    subject: 'Attempt offices own improve now see.',
    preview: 'Called person are around county talked her esteem. Those fully these way nay thing seems.',
    attach: false,
    favorite: true,
    time: 'July 05',
    unread: false,
  },
];
