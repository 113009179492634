import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { MDBDataTable } from 'mdbreact';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Datatable from 'react-bs-datatable';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;
const image = `${process.env.PUBLIC_URL}/img/map-location.png`;

const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "status_title",
    text: "Status",
    sort: true,
    headerStyle: { width: "20%" }
  },
  {
    dataField: "user_lat",
    text: "Latitude",
    sort: true,
    headerStyle: { width: "20%" }
  },
  {
    dataField: "user_long",
    text: "Longitude",
    sort: true,
    headerStyle: { width: "20%" }
  },
  {
    dataField: "created_at",
    text: "Date/Time",
    headerStyle: { width: "20%" },
    csvExport: false
  },
];

class PreTriggerNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData: [],
    }
    this.flag = 0;
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
  }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      var followme_id = answer_array['3'];

      fetch(myConstClass.BASE_URL + '/user/getFollowMeSafeRequest/' + followme_id)
        .then(response => response.json())
        .then(response => {
          let i = 1;
          response.forEach(element => {
            var id = element.id;
            var user_id_value = element.user_id_value;
            var pre_trigger_id = element.pre_trigger_id;
            const file_link = `${myConstClass.BASE_URL + '/user/getPreTriggerNotificationsByUser/' + user_id_value + "/" + pre_trigger_id}`;
            element.view_map = <div><center>
              <a target="_blank" href={file_link} title="View Map"><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>
            </center></div>;
            element.id = i;
            i++;
          });
          this.setState({ formData: response })
        })
        .catch(err => console.log(err))
    }

    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }

  viewFollowMeSafe(e) {
    this.props.history.push('/forms/view_followme_safe/' + e);
  }

  render() {
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Follow me Safe
            </h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          // sizePerPage: 25,
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(PreTriggerNotifications);
