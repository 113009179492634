

import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import $ from 'jquery';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "message_date",
    text: "Message Date",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "title",
    text: "Title",
    sort: true,
    headerStyle: { width: "20%" }
  },
  {
    dataField: "message",
    text: "Message",
    sort: true,
    headerStyle: { width: "20%" }
  },
  // {
  //   dataField: "days",
  //   text: "Days",
  //   sort: true,
  //   headerStyle: { width: "10%" }
  // },
  {
    dataField: "created_at",
    text: "Date/Time",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "action",
    text: "Action",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];

class Safety_tips_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData: [],
      formDataDownload: {
        sapcode: '',
      },
      reportsapid: '',
      samplelink: myConstClass.BASE_URL + '/safety_tips/downloadexcelsheetSafetytips',
      selectedFile: '',
    }
    this.flag = 0;
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  view(e) {
    this.props.history.push('/forms/safety_tips_msg_view/' + e);
  }

  // downloadexcelsheet() {
  //   toast('Excel sheet file is generating.', { containerId: 'B', type: toast.TYPE.SUCCESS })
  //     const data = new FormData()
  //   data.append('e_id', 1);
  //   fetch(myConstClass.BASE_URL + `/safetytips/downloadexcelsheetSafetytips`, {
  //     method: 'POST',
  //     body: data,
  //   }).then(response => response.json()).then((response) => {
  //     if (response) {
  //       //  toast('Pdf is generating', {containerId: 'B',type: toast.TYPE.SUCCESS})
  //       window.open(myConstClass.BASE_URL + `/message/downloadexcelsheet`, "_blank");
  //     } else {
  //       //toast('Pdf generating Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
  //     }
  //   }).catch();
  // }

  onFileChange = event => {
    // Update the state 
    this.setState({ selectedFile: event.target.files[0] });
  };

  // On file upload (click the upload button) 
  onFileUpload = () => {
    if (this.state.selectedFile == "") {
      toast('Please select file', { containerId: 'B', type: toast.TYPE.ERROR })
    } else {
      // Create an object of formData 
      const formData = new FormData();
      // Update the formData object 
      formData.append(
        "morning_greetings_file",
        this.state.selectedFile,
        this.state.selectedFile.name
      );
      // Details of the uploaded file
      // Request made to the backend api Send formData object 
      fetch(myConstClass.BASE_URL + '/safety_tips/uploadexcelsheetSafetyTips', {
        method: 'POST',
        body: formData,
      }).then(response => response.json()).then((response) => {
        if (response) {
          toast('Result file', { containerId: 'B', type: toast.TYPE.SUCCESS })
          window.open(response.msg_file, "_blank");
          fetch(myConstClass.BASE_URL + '/safety_tips/getSafetyTips')
            .then(response => response.json())
            .then(response => {
              let i = 1;
              response.forEach(element => {
                var id = element.id;
                element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() => this.view(id)}></i>&nbsp;&nbsp;<i className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i>&nbsp;&nbsp;<i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i>&nbsp;</center></div>;
                element.id = i;
                i++;
              });
              this.setState({ formData: response })
            }).catch(err => console.log(err))
        } else {
          //toast(Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
        }
      }).catch();
    }
  };

  handleChange(event) {
    this.setState({ reportsapid: event.target.value });
  }

  add_morninggreetings_message() {
    this.props.history.push('/forms/add_safety_tips_msg');
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  edit(e) {
    this.props.history.push('/forms/edit_safety_tips_msg/' + e);
  }

  remove(e) {
    this.flag = 1;
    fetch(myConstClass.BASE_URL + `/safety_tips/deleteSafetyTips`, {
      method: 'POST',
      body: e,
      //}).then(toast('Message Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS }),
    }).then(response => response.json()).then((response) => {
      if (response == true) {
        toast('Message Deleted Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })

        fetch(myConstClass.BASE_URL + '/safety_tips/getSafetyTips')
          .then(response => response.json())
          .then(response => {
            let i = 1;
            response.forEach(element => {
              let id = element.id;
              element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() => this.view(id)}></i>&nbsp;&nbsp;<i className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i>&nbsp;&nbsp;<i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i></center></div>;
              element.id = i;
              i++;
            });
            this.setState({ formData: response })
          }).catch(err => console.log(err))
      } else {
        toast('Failed to delete.', { containerId: 'B', type: toast.TYPE.ERROR })
      }
    }).catch();
  }

  componentDidMount() {
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
    }
    // fetch(myConstClass.BASE_URL + '/safety_tips/getSafetyTips')
    fetch(myConstClass.BASE_URL + '/safety_tips/getSafetyTips/'+tid)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          var id = element.id;
          element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() => this.view(id)}></i>&nbsp;&nbsp;<i className="fa fa-remove ptr_css" onClick={() => this.remove(id)}></i>&nbsp;&nbsp;<i id={id} className="fa fa-edit ptr_css" onClick={() => this.edit(id)}></i></center></div>;
          element.id = i;
          i++;
        });
        this.setState({ formData: response })
      }).catch(err => console.log(err))
    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }

  }

  render() {

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">All Safety Tips Message
            </h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              {/* <CardBody> */}

              <ToolkitProvider
                keyField="name"
                data={this.state.formData}
                columns={tableColumns}
                exportCSV
                search
              >
                {props => (
                  <div>
                    <CardBody>
                      <Row>
                        <Col md={4} lg={4}>
                          <CardTitle tag="h5">Download Sample Excel Sheet</CardTitle>
                        </Col>
                        <Col md={4} lg={4}>
                          <a className="btn btn-primary" href={this.state.samplelink}>Download Sample Excel Sheet</a>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} lg={4}>
                          <input type="file" onChange={this.onFileChange} />
                        </Col>
                        <Col md={4} lg={4}>
                          <Button color="primary" onClick={this.onFileUpload}> Upload </Button>
                        </Col>
                      </Row>
                      <hr />
                      <Button color="primary" style={{ float: 'right' }} onClick={() => this.add_morninggreetings_message()} > Add Safety Tips Message </Button>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4
                        bordered={false}
                        pagination={paginationFactory({
                          // sizePerPage: 25,
                          sizePerPage: 10,
                          sizePerPageList: [5, 10, 25, 50]
                        })}
                      />
                    </CardBody>
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Safety_tips_list);
