import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
const image = `${process.env.PUBLIC_URL}/img/map-location.png`;

const { SearchBar } = Search;
const tableColumns = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerStyle: { width: "4%" }
  },
  {
    dataField: "trackee_id_name",
    text: "User",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "trackee_id_count",
    text: "Trips",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "action",
    text: "Action",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];

const tableColumnsTenant = [
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerStyle: { width: "4%" }
  },
  {
    dataField: "trackee_id_name",
    text: "User",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "trackee_id_count",
    text: "Trips",
    sort: true,
    headerStyle: { width: "10%" }
  },
  {
    dataField: "action",
    text: "Action",
    headerStyle: { width: "10%" },
    csvExport: false
  },
];
class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData: [],
      tid: "",
    }
    this.flag = 0;
    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
  }

  view(e) {
    this.props.history.push('/maps/google_map/' + e);
  }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

  componentDidMount() {
    var tid = 1;
    if (localStorage.getItem('uid')) {
      tid = localStorage.getItem('uid');
      this.setState({ tid: tid })
    }
    fetch(myConstClass.BASE_URL + '/user/getTripTracking/' + tid)
      .then(response => response.json())
      .then(response => {
        let i = 1;
        response.forEach(element => {
          var id = element.id;
          element.action = <div><i id={id} className="fa fa-eye ptr_css" onClick={() => this.viewDetail(element.trackee_id)} title="View Trip"></i>  </div>;
          const file_link = `${myConstClass.BASE_URL + '/user/getTrackingById/' + id}`;
          const file_link_google_map = `${myConstClass.BASE_URL + '/user/getTrackingMapById/' + id}`;
          element.view_map = <div><center>
            <a target="_blank" href={file_link_google_map} title="View Map"><img id={id} src={image} style={{ width: '20px', height: '16px' }} /></a>
          </center></div>;
          element.id = i;
          i++;
        });
        this.setState({ formData: response })
      })
      .catch(err => console.log(err))

    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
  }
  viewDetail(e) {
    this.props.history.push('/forms/trip_detail/' + e);
  }

  render() {
    const { tid } = this.state;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">All Trips
            </h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>

        <Row>
          <Col md={12} lg={12}>
            <Card>
              {tid == 1 ? (
                <ToolkitProvider
                  keyField="name"
                  data={this.state.formData}
                  columns={tableColumns}
                  exportCSV
                  search
                >
                  {props => (
                    <div>

                      <CardBody>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4
                          bordered={false}
                          pagination={paginationFactory({
                            // sizePerPage: 25,
                            sizePerPage: 10,
                            sizePerPageList: [5, 10, 25, 50]
                          })}
                        />
                      </CardBody>
                    </div>
                  )}
                </ToolkitProvider>
              ) : (
                <ToolkitProvider
                  keyField="name"
                  data={this.state.formData}
                  columns={tableColumnsTenant}
                  exportCSV
                  search
                >
                  {props => (
                    <div>
                      <CardBody>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4
                          bordered={false}
                          pagination={paginationFactory({
                            // sizePerPage: 25,
                            sizePerPage: 10,
                            sizePerPageList: [5, 10, 25, 50]
                          })}
                        />
                      </CardBody>
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Tracking);
