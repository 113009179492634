import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody,ButtonToolbar,} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { MultipleSelect } from "react-select-material-ui";

class Location_group_add extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      user_id:'',
      codes:[],

      formData: {
        title: '',
        branch_code: '',
        locations:[]
      },
    submitted: false,
    isButtonDisabled:true,
    isButtonDisabled1:true,
    }
    
  }
  componentDidMount(){
   
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
      //alert(answer_array['3']);
      this.state.user_id = answer_array['3'];
      fetch(myConstClass.BASE_URL+'/user/getUserGroupLocationInfo/'+answer_array['3'])
      .then(response => response.json())
      .then(response =>{this.setState({formData: response})}).catch(err => console.log(err))
      
    }
    fetch(myConstClass.BASE_URL+'/user/getAllBranchCode/')
    .then(response => response.json())
    .then(response =>{this.setState({codes: response})}).catch(err => console.log(err))

    if(!localStorage.getItem('session_id')){
      console.log('History:',this.props);      
      this.props.history.push('/log_in');
  }

  }

  handleLocationChange=(values) => {
    console.log(values);
    const { formData } = this.state;
    formData['locations'] = values;
    this.setState({ formData });
  };
  
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

handleSubmit = () => {
 
  this.setState({ submitted: true }, () => {
      var result = {};
      result['title']=this.state.formData.title;
      result['locations']=this.state.formData.locations;
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL+`/user/addGroupLocation/`+this.state.user_id, {
              method: 'POST',
              body: JSON.stringify(result),
            }).then(response =>  response.json()).then((response) => {
              if(response == true){
                toast('Location Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
                this.props.history.push('/forms/group_locations/');
              }else{
                toast('User Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
              }
            })
            .catch();
  });
}


  render() {
    const { formData, submitted,states,districts,countries,isButtonDisabled,isButtonDisabled1 } = this.state;
    const { codes} = this.state;    
    return (
    <Container>
    <Row>
      <Col md={12}>
        {this.state.user_id ? <h3 className="page-title">Edit Group Location</h3>:<h3 className="page-title">Add Group Location</h3>}
        {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
              information
        </h3> */}
      </Col>
    </Row>
    <Row>   
     
    <Card>
      <CardBody>
        <div className="card__title">
          {/* <h5 className="bold-text">{t('forms.floating_labels_form.animated_line_inputs')}</h5>
           */}
        {/* <h5 className="bold-text">Add Admin/Employee {posts.sap_code}</h5> */}
        {/* <h5 className="subhead">Material fields with a property <span className="red-text">label</span></h5> */}
        </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
        </div>
        
        <ValidatorForm
                className="material-form" id="pform" 
                onSubmit={this.handleSubmit}
            >      
     
          <Row>
          
        <Col md={6} lg={6}>            
            <TextValidator
             onChange={this.handleChange}
             name="title"          
             label="Title"
             value={formData.title}
             validators={['required']}
             errorMessages={['this field is required']}
             className="material-form__field"
            />            
         <br/> 
         
        <MultipleSelect
          label="Choose some Locations"         
          values={formData.locations}
          options={codes}
          onChange={this.handleLocationChange}
          helperText="You can search Location by writing its name and pressing enter"         
          SelectProps={{
            isCreatable: true,
            msgNoOptionsAvailable: "All Locations are selected",
            msgNoOptionsMatchFilter: "No location name matches the filter"
          }}
        />
          </Col>
          </Row>
          <br />
          <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={submitted}
                >
                    {
                        (submitted && 'Your form is submitted!')
                        || (!submitted && 'Submit')
                    }
                </Button>
               
          </ValidatorForm>
          </CardBody>
    </Card>
 
      {/* <AnimatedLineFormWithLabels onSubmit={showResults} posts={posts}/> */}
    </Row>
  </Container>
    )

  }

}



export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(Location_group_add));
