import React ,{ Component } from 'react';
import { Col, Container, Row,Button,Card, CardBody, ButtonToolbar  } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { Field, reduxForm } from 'redux-form';
import FileUploadDefault from './components/FileUploadDefault';
import FileUploadCustomHeight from './components/FileUploadCustomHeight';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import CsvDownloader from 'react-csv-downloader';
const file_link = `${process.env.PUBLIC_URL}/SAMPLE File.csv`;
const loader = `${process.env.PUBLIC_URL}/loader.gif`;
const rolling = `${process.env.PUBLIC_URL}/img/Rolling.gif`;
const columns = [{
  id: 'id',
  displayName: 'ID'
}, {
  id: 'sap_code',
  displayName: 'SAP Code'
},{
  id: 'first_name',
  displayName: 'First Name'
},{
  id: 'middle_name',
  displayName: 'Middle Name'
},{
  id: 'last_name',
  displayName: 'Last Name'
},{
  id: 'mobile',
  displayName: 'Mobile'
},{
  id: 'email',
  displayName: 'Email'
},{
  id: 'designation',
  displayName: 'Designation'
},{
  id: 'department',
  displayName: 'Department'
},{
  id: 'gender',
  displayName: 'Gender'
},{
  id: 'blood_group',
  displayName: 'Blood Group'
},{
  id: 'age',
  displayName: 'Age'
},{
  id: 'dob',
  displayName: 'DOB'
},{
  id: 'branch_name',
  displayName: 'Branch Name'
},{
  id: 'contact_1',
  displayName: 'Contact 1'
},{
  id: 'contact_2',
  displayName: 'Contact 2'
},{
  id: 'contact_3',
  displayName: 'Contact 3'
},{
  id: 'contact_4',
  displayName: 'Contact 4'
},{
  id: 'contact_5',
  displayName: 'Contact 5'
},{
  id: 'contact_6',
  displayName: 'Contact 6'
},{
  id: 'status',
  displayName: 'Status'
}];

const datas = [{
  id: 'foo',
  sap_code: 'bar'
}, {
  id: 'foobar',
  sap_code: 'foobar'
}];
class Bulk_import extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      posts:'',
      dusers:''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
    if(!localStorage.getItem('session_id')){
      console.log('History:',this.props);      
      this.props.history.push('/log_in');
  }
  }
  handleSubmit = () => {
    alert(JSON.stringify($('#pform').serializeArray()));
  }
  onChangeHandler=event=>{

    console.log(event.target.files[0]);
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })

}
onClickHandler = () => {
  const data = new FormData() 
  data.append('file', this.state.selectedFile);
  data.append('session_id', localStorage.getItem('session_id'));
  $('#loader').show();
  fetch(myConstClass.BASE_URL+`/user/import`, {
    method: 'POST',
    body: data,
  }).then(response => response.json())
  .then(response =>{
    this.setState({posts: response});
    $('#loader').hide();

  fetch(myConstClass.BASE_URL+`/user/getDeclinedUser`, {
    method: 'POST',
    body: data,
  }).then(response => response.json())
  .then(response =>this.setState({dusers: response}))

  })

}

onSaveClickHandler = () => {
  const data = new FormData() 
  data.append('file', this.state.selectedFile);
  data.append('session_id', localStorage.getItem('session_id'));
  fetch(myConstClass.BASE_URL+`/user/save_import_data`, {
    method: 'POST',
    body: data,
  }).then(response =>  response.json()).then((response) => {
    if(response){
      toast('User Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
      this.props.history.push('/forms/users/');
    }else{
      toast('User Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
    }
  })
  .catch();


}



  render() {
   
    return (
      <Container>
    <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
    <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    <Row>
      <Col md={12}>
        <h3 className="page-title">Employee Bulk Import</h3>
         
      </Col>
    
    <Card>
      <CardBody>      
      <h5>
         <b>Import CSV file only</b><br/><br/>
The first line in downloaded csv file should remain as it is. Please do not change the order of columns in csv file.
<br/>
The correct column order is <br/>(SAP Code(*), First Name(*), Middle Name, Last Name(*), Mobile(*), Email, designation, Department_Name, Gender, Blood Group, Age, DOB, Branch Code, Emergency Contact SAP Code1(*), Emergency Contact SAP Code2(*), Emergency Contact SAP Code3(*), Emergency Contact SAP Code4(*), Employee Status
) <br/>and you must follow the csv file, otherwise your data will not be saved.
        </h5> 
        <hr/>
         { this.state.posts ?
         <div>
           <Row>
           
   <Col md={10}>
            <h5>Insert : {this.state.posts.icnt} &nbsp;&nbsp; Update : {this.state.posts.ucnt} &nbsp;&nbsp; Error : {this.state.posts.ecnt}</h5>
            </Col>
            <Col md={2}>
      <CsvDownloader
        filename="DeclinedUser"
        separator=","
        columns={columns}
        datas={this.state.dusers}
        className="btn btn-primary btn-block col-md-2"
        text="View Declined User" />
   </Col>   
        </Row>
            <hr/>
          <MDBDataTable
      striped
      hover
      data={this.state.posts}
    />
    <Col md={6}>
  <button type="button" className="btn btn-primary btn-block col-md-4" onClick={this.onSaveClickHandler}>Save</button>
    
    </Col>
    </div>
  : 
  <form className="form" id="pform" onSubmit={this.handleSubmit}>
  <Col md={3}>
  <input type="file" name="file" onChange={this.onChangeHandler}/>   
  </Col>
 
    <Col md={6}>
    <Row>
  <button type="button"  style={{width: '40%',height:'10%'}} className="btn btn-primary btn-block col-md-4" onClick={this.onClickHandler}>Upload</button>
  <img src={loader}  id="loader" style={{width: '12%',display:'none'}}  />
  </Row>
  </Col>
  
  <Col md={3}>
  <a href={file_link} style={{float:'right'}}>
  <Button color="primary" type="button" className="previous">Download Sample File</Button>
  </a>
  </Col> </form>  }
  
      </CardBody>
      </Card></Row>
  </Container>
    )
  }
}

Bulk_import.propTypes = {
  //handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
export default reduxForm({
  form: 'Bulk_import', // a unique identifier for this form
})(withTranslation('common')(Bulk_import));

