export default [
  {
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/25.png`,
    title: 'Blue Vase',
    price: '12',
    description: 'Awesome item from Glassy brand',
    colors: ['#00b3c6', '#50e3c2', '#fa4a86'],
    new: true,
  },
  {
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/26.png`,
    title: 'Pink Vase',
    price: '17',
    description: 'Awesome item from Glassy brand',
    colors: ['#d4d4d4', '#5ff4d3', '#f7a9c4'],
    new: true,
  },
  {
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/27.png`,
    title: 'Bulldog Pillow',
    price: '17',
    description: 'Awesome item from Glassy brand',
    colors: ['#fa93bd'],
    sale: true,
    oldPrice: '23',
  },
  {
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/28.png`,
    title: 'Yellow Pillow',
    price: '16',
    description: 'Awesome item from Glassy brand',
    colors: ['#c6890a', '#faee5a'],
  },
  {
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/27.png`,
    title: 'Bulldog Pillow',
    price: '17',
    description: 'Awesome item from Glassy brand',
    colors: ['#fa93bd'],
    sale: true,
    oldPrice: '23',
  },
  {
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/28.png`,
    title: 'Yellow Pillow',
    price: '16',
    description: 'Awesome item from Glassy brand',
    colors: ['#c6890a', '#faee5a'],
  },
  {
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/25.png`,
    title: 'Blue Vase',
    price: '120',
    description: 'Awesome item from Glassy brand',
    colors: ['#00b3c6', '#50e3c2', '#fa4a86'],
    new: true,
  },
  {
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/26.png`,
    title: 'Pink Vase',
    price: '17',
    description: 'Awesome item from Glassy brand',
    colors: ['#d4d4d4', '#5ff4d3', '#f7a9c4'],
    new: true,
  },
];
