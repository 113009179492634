import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody, ButtonToolbar, } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import showResults from '../Show';
import * as myConstClass from '../../../constant.js';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Field, reduxForm } from 'redux-form';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { DatePicker } from "material-ui-pickers";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    name={input.name}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      alert('in..' + e.target.value);
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};

class Safety_tips_add extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: Date.now(),
      user_id: '',
      formData: {
        message_date: Date.now(),
        message: '',
      },
      submitted: false,
      isButtonDisabled: true,
      isButtonDisabled1: true,
      tenant_id:'',
    }
  }
  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    // if (answer_array['3']) {
    //   this.state.user_id = answer_array['3'];
    //   fetch(myConstClass.BASE_URL + '/user/getUpdateMorningGreetingsInfo/' + answer_array['3'])
    //     .then(response => response.json())
    //     .then(response => { this.setState({ formData: response }) }).catch(err => console.log(err))
    // }

    if (!localStorage.getItem('session_id')) {
      console.log('History:', this.props);
      this.props.history.push('/log_in');
    }
    if (localStorage.getItem('uid')) {
      this.setState({ tenant_id: localStorage.getItem('uid') });
    }
  }
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }
  handleDateChange = (message_date) => {
    const { formData } = this.state;
    formData['message_date'] = message_date;
    this.setState({ formData });
  };

  // handleDateChange = (date) => {  
  //   alert(date);
  //   const { formData } = this.state;
  //   formData['message_date'] = date;
  //   var ageDifMs = Date.now() - date.getTime();
  //   var ageDate = new Date(ageDifMs); // miliseconds from epoch
  //   var message_date = Math.abs(ageDate.getUTCFullYear() - 1970);
  //   alert(message_date);
  //   formData['message_date'] = message_date;
  //   this.setState({ formData });
  // };
  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.BASE_URL + `/safety_tips/checkDateSafetyTips`, {
        method: 'POST',
        body: JSON.stringify($('#pform').serializeArray()),
      }).then(response => response.json()).then((response) => {
        if (response == 0) {
          fetch(myConstClass.BASE_URL + `/safety_tips/AddSafetyTipsInfo`, {
            method: 'POST',
            body: JSON.stringify($('#pform').serializeArray()),
          }).then(response => response.json()).then((response) => {
            if (response == true) {
              toast('Message Added Successfully..!', { containerId: 'B', type: toast.TYPE.SUCCESS })
              this.props.history.push('/forms/safety_tips_msg/');
            } else {
              toast('Message Insertion Failed..!', { containerId: 'B', type: toast.TYPE.ERROR })
            }
          }).catch();
        } else {
          toast('Same date record already exists.', { containerId: 'B', type: toast.TYPE.ERROR })
        }
      }).catch();
    });
  }

  render() {
    const { formData, submitted, isButtonDisabled, isButtonDisabled1 } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Add Safety Tips Message </h3>
          </Col>
        </Row>
        <Row>
          <Card>
            <CardBody>
              <div className="card__title">
              </div>
              <div>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
              </div>
              <ValidatorForm
                className="material-form" id="pform"
                onSubmit={this.handleSubmit}
              >
                <input type="hidden" name="tenant_id" value={this.state.tenant_id} />
                <Row>
                  <Col md={6} lg={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        className="material-form__field"
                        onChange={this.handleDateChange}
                        name="message_date"
                        placeholder="Message Date"
                        label="Message Date (yyyy-mm-dd)"
                        value={formData.message_date}
                        format="yyyy-MM-dd"
                      />
                    </MuiPickersUtilsProvider>
                    <div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    <TextValidator
                      onChange={this.handleChange}
                      name="title"
                      label="Message Title"
                      value={formData.title}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      className="material-form__field"
                    />
                    <div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    <h6  >Message Description</h6>
                    <textarea
                      name="message"
                      className="material-form__field"
                      value={formData.message}
                      rows="5"
                      validators={['required']}
                      errorMessages={['this field is required']}
                      onChange={this.handleChange}
                    />
                    <div>
                    </div>
                  </Col>
                </Row>
                {/*<Row>
                  <Col md={6} lg={6}>
                     <h6  >Days</h6>
                     <TextValidator
                      onChange={this.handleChange}
                      name="days"
                      label="Days"
                      value={formData.days}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      className="material-form__field"
                    />
                    <div>
                    </div>
                  </Col>
                </Row>*/}
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                >
                  {
                    (submitted && 'Your form is submitted!')
                    || (!submitted && 'Submit')
                  }
                </Button>
              </ValidatorForm>
            </CardBody>
          </Card>
        </Row>
      </Container>
    )
  }
}

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(Safety_tips_add));
