/* eslint-disable react/no-children-prop */
import React,{ Component } from 'react';
import {
  Card, CardBody, Col,Row, Button, ButtonToolbar,
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const renderTextField = ({
  input, label, meta: { touched, error }, children, select,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};


const AnimatedLineFormWithLabels = ({ handleSubmit, reset, t,posts }) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          {/* <h5 className="bold-text">{t('forms.floating_labels_form.animated_line_inputs')}</h5>
           */}
        {/* <h5 className="bold-text">Add Admin/Employee {posts.sap_code}</h5> */}
        {/* <h5 className="subhead">Material fields with a property <span className="red-text">label</span></h5> */}
        </div>
        <div>
            <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
            <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
        </div>
        <form className="material-form" id="pform" onSubmit={handleSubmit}>
        
        <Row>
        <Col md={6} lg={6}>
          <div>
            <Field
                  name="user_type"
                  component={renderRadioButtonField}
                  label="Admin"
                  radioValue="2"
                  defaultChecked
                  className="colored"
                />
          </div>
          </Col>
          <Col md={6} lg={6}>
          <div>
            <Field
                  name="user_type"
                  component={renderRadioButtonField}
                  label="Employee"
                  radioValue="3"
                  className="colored"
                />
          </div>
          </Col>
          </Row>
         
          
       
          <Row>
          
        <Col md={6} lg={6}>
        <div>       
            <Field
              name="sapcode"
              component={renderTextField}
              placeholder="SAP Code1"
              label="SAP Code"
            />            
          </div>
          <div>
            <Field
              name="firstname"
              component={renderTextField}
              placeholder="First name"
              label="First name"
            />
          </div>
        <div>
            <Field
              name="middlename"
              component={renderTextField}
              placeholder="Middle name"
              label="Middle name"
            />
          </div>
          <div>
            <Field
              name="lastname"
              component={renderTextField}
              placeholder="Last name"
              label="Last name"
            />
          </div>
          <div>
            <Field
              name="email"
              component={renderTextField}
              placeholder="example@mail.com"
              type="email"
              label="Email"
            />
          </div>
          <div>
            <Field
              name="mobile_no"
              component={renderTextField}
              placeholder="Mobile No"
              label="Mobile No"
            />
          </div>
          <div>
          <Field
              name="branch_name"
              component={renderTextField}
              placeholder="Branch Name"
              label="Branch Name"
            />
          </div>
         
          <div>
            <Field
              name="designation"
              component={renderTextField}
              placeholder="Designation"
              label="Designation"
            />
          </div>
          <div>
          <Field
              name="department"
              component={renderTextField}
              placeholder="Department"
              label="Department"
            />
          </div>
          <div>
          <Field
              name="vertical"
              component={renderTextField}
              placeholder="Vertical"
              label="Vertical"
            />
          </div>
          <div>
          <Field
              name="gender"
              component={renderTextField}
              select
              label="Select Gender"
            >
              <MenuItem className="material-form__option" value="1">Male</MenuItem>
              <MenuItem className="material-form__option" value="2">Female</MenuItem>
              <MenuItem className="material-form__option" value="3">Others</MenuItem>
            </Field>
          </div>
          <div>
          <Field
              name="blood_group"
              component={renderTextField}
              select
              label="Select Blood Group"
            >
              <MenuItem className="material-form__option" value="1">A+</MenuItem>
              <MenuItem className="material-form__option" value="2">B+</MenuItem>
              <MenuItem className="material-form__option" value="3">O+</MenuItem>
              <MenuItem className="material-form__option" value="4">AB+</MenuItem>
              <MenuItem className="material-form__option" value="5">A-</MenuItem>
              <MenuItem className="material-form__option" value="6">B-</MenuItem>
              <MenuItem className="material-form__option" value="7">O-</MenuItem>
              <MenuItem className="material-form__option" value="8">AB-</MenuItem>
              
            </Field>
          </div>
          
          </Col>
          <Col md={6} lg={6}>
         
          <div>
          <Field
                name="date_of_birth"
                component={renderTextField}
                placeholder="Date of Birth"
                label="Date of Birth"
              />
          </div>
          <div>
          <Field
                name="age"
                component={renderTextField}
                placeholder="Age"
                label="Age"
              />
          </div>
          <div>
          <Field
                name="reporting_manager_sap_code"
                component={renderTextField}
                placeholder="Reporting Manager SAP Code"
                label="Reporting Manager SAP Code"
              />
          </div>
          <div>
          <Field
                name="reporting_manager_name"
                component={renderTextField}
                placeholder="Reporting Manager Name"
                label="Reporting Manager Name"
              />
          </div>
          <div>
          <Field
                name="reviewing_manager_sap_code"
                component={renderTextField}
                placeholder="Reviewing Manager SAP Code"
                label="Reviewing Manager SAP Code"
              />
          </div>
          <div>
          <Field
                name="reviewing_manager_name"
                component={renderTextField}
                placeholder="Reviewing Manager Name"
                label="Reviewing Manager Name"
              />
          </div>
          <div>
          <Field
                name="ho_poc_sap_code"
                component={renderTextField}
                placeholder="HO POC SAP Code"
                label="HO POC SAP Code"
              />
          </div>
          <div>
          <Field
                name="ho_poc_name"
                component={renderTextField}
                placeholder="HO POC Name"
                label="HO POC Name"
              />
          </div>
          <div>
          <Field
                name="department_head_sap_code"
                component={renderTextField}
                placeholder="Department Head SAP Code"
                label="Department Head SAP Code"
              />
          </div>
          <div>
          <Field
                name="department_head_name"
                component={renderTextField}
                placeholder="Department Head Name"
                label="Department Head Name"
              />
          </div>
          <div>
          <Field
              name="status"
              component={renderTextField}
              select
              label="Select Status"
            >
              <MenuItem className="material-form__option" value="1">Active</MenuItem>
              <MenuItem className="material-form__option" value="2">Inactive</MenuItem>
              
            </Field>
          </div>
          </Col>
          </Row>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">Submit</Button>
            <Button type="button" onClick={reset}>
                  Cancel
            </Button>
          </ButtonToolbar>
        </form>
      </CardBody>
    </Card>
  </Col>
);

AnimatedLineFormWithLabels.propTypes = {
   handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(withTranslation('common')(AnimatedLineFormWithLabels));
